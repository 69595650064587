import React, { createRef, FormEvent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button, Icon, Input, PasswordInput } from '@column/column-ui-kit';

import { SectionMfaSms } from '~/app/pages/User/Personal/SectionMfaSms';
import { SectionMfaTotp } from '~/app/pages/User/Personal/SectionMfaTotp';
import { Notification, PageHeader, SectionHeader } from '~/components';
import { PlaneButton } from '~/elements';
import { FeatureFlag, useFeatureFlag } from '~/lib/flags';
import { UserRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { FormElement, FormLabel, Grid, Inner } from '~/styles';
import { log } from '~/util';
import { reCaptchaCheck } from '~/util/reCaptcha';

const Section = styled.div`
  padding: 0 24px 24px;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;

  @media (max-width: 1400px) {
    grid-template-columns: 25% repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px 24px;
  }
`;

const Info = styled.div`
  display: flex;
  gap: 16px;
  align-self: end;
`;

const Resend = styled(Button)`
  min-width: 108px;
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NotificationContainer = styled.div`
  position: relative;
  width: 196px;
  height: 40px;
`;

const NotificationItem = styled.div<{ show: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  transition:
    opacity 0.2s,
    transform 0.2s;

  ${({ show }) =>
    !show &&
    css`
      transform: translateY(-4px);
      opacity: 0;
      pointer-events: none;
    `}

  ${({ show }) =>
    show &&
    css`
      transition-delay: 0.15s;
    `}
`;

export const PagePersonal: React.FC = () => {
  const { isLoading, currentUser, refetchCurrentUser } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const deviceMFAEnabled = useFeatureFlag(FeatureFlag.EnableDeviceMFA);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>('');

  const notificationContainer = createRef<HTMLDivElement>();

  const { handleReCaptcha } = reCaptchaCheck();

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    setFirstName(currentUser.firstName ?? '');
    setLastName(currentUser.lastName ?? '');
    setEmail(currentUser.email ?? '');
    setPhoneNumber(currentUser.phoneNumber ?? '');
  }, [currentUser, isLoading]);

  if (!currentUser) {
    return null;
  }

  const handleVerifyEmail = (resend?: boolean) => {
    currentUser.createVerifyEmail();

    log({
      name: `User email verify${resend ? ' resent' : ''}`,
      context: currentUser,
    });

    if (!resend) {
      return;
    }

    addSuccessNotification({
      content: 'Verification email resent! Check your inbox.',
      display: 'page',
    });
  };

  const handleSave = (event: FormEvent) => {
    event.preventDefault();

    handleReCaptcha(
      (reCaptchaToken) =>
        currentUser
          .update(
            {
              firstName,
              lastName,
              email,
              phoneNumber,
            },
            reCaptchaToken
          )
          .then(() => {
            addSuccessNotification({
              content: 'User data updated',
              display: 'page',
            });
            refetchCurrentUser();
          })
          .catch((error) => {
            addDangerNotification({
              content: error.message,
              display: 'page',
            });
          }),
      (err) => {
        addDangerNotification({
          content: err.message,
          display: 'page',
        });
      }
    );

    if (newPassword) {
      if (newPassword !== newPasswordRepeat) {
        addDangerNotification({
          content: 'Passwords are not the same',
          display: 'page',
        });
        return;
      }

      UserRepository.updatePassword({
        currentPassword,
        newPassword,
      })
        .then(() => {
          addSuccessNotification({
            content: 'Password changed',
            display: 'page',
          });
        })
        .catch((error) => {
          addDangerNotification({
            content: error.message,
            display: 'page',
          });
        });
    }
  };

  return (
    <>
      <PageHeader text="Profile" />
      <form autoComplete="off" onSubmit={handleSave}>
        <SectionHeader text="Personal Information" />
        <Section>
          <Grid>
            <FormElement>
              <FormLabel>First Name</FormLabel>
              <Input value={firstName} onChange={(value: string) => setFirstName(value)} placeholder="First name" />
            </FormElement>
            <FormElement>
              <FormLabel>Last Name</FormLabel>
              <Input value={lastName} onChange={(value: string) => setLastName(value)} placeholder="Last name" />
            </FormElement>

            <FormElement>
              <FormLabel>Email</FormLabel>
              <Input value={email} onChange={(value: string) => setEmail(value)} placeholder="Email" />
            </FormElement>

            <Info>
              {!currentUser.isEmailVerified &&
                (currentUser.isEmailPending ? (
                  <Resend type="button" onClick={() => handleVerifyEmail(true)} icon={<Icon.Mail />} variant="muted">
                    Resend
                  </Resend>
                ) : (
                  <PlaneButton
                    onDone={() => handleVerifyEmail()}
                    icon={<Icon.Mail />}
                    success={
                      <Button onClick={() => handleVerifyEmail()} icon={<Icon.Mail />} variant="muted">
                        Resend
                      </Button>
                    }
                  >
                    Verify
                  </PlaneButton>
                ))}
              <NotificationContainer ref={notificationContainer}>
                <NotificationItem show={currentUser.isEmailPending}>
                  <Notification color="info" variant="light" withClose={false}>
                    Verification pending
                  </Notification>
                </NotificationItem>
                <NotificationItem show={currentUser.isEmailVerified}>
                  <Notification variant="light" withClose={false}>
                    Email verified
                  </Notification>
                </NotificationItem>
                <NotificationItem show={!currentUser.isEmailPending && !currentUser.isEmailVerified}>
                  <Notification color="danger" variant="light" withClose={false}>
                    Email not verified
                  </Notification>
                </NotificationItem>
              </NotificationContainer>
            </Info>
          </Grid>
        </Section>

        <SectionHeader text="Two-factor authentication" border />
        <Section>
          <SectionMfaSms onPhoneNumberChange={setPhoneNumber} />
          {deviceMFAEnabled && <SectionMfaTotp />}
        </Section>

        <SectionHeader text="Password" border />
        <Section>
          <Grid>
            <FormElement>
              <FormLabel>New Password</FormLabel>
              <PasswordInput value={newPassword} onChange={setNewPassword} placeholder="New password" />
            </FormElement>
            <FormElement>
              <FormLabel>Repeat Password</FormLabel>
              <PasswordInput value={newPasswordRepeat} onChange={setNewPasswordRepeat} placeholder="Repeat password" />
            </FormElement>
            <FormElement>
              <FormLabel>Current Password</FormLabel>
              <PasswordInput value={currentPassword} onChange={setCurrentPassword} placeholder="Current password" />
            </FormElement>
          </Grid>
        </Section>
        <Inner>
          <Action>
            <Button type="submit" size="small">
              Save
            </Button>
          </Action>
        </Inner>
      </form>
    </>
  );
};
