import React, { useState } from 'react';
import styled from 'styled-components';

import { Icon, Input, Button, Textarea, Dropdown } from '@column/column-ui-kit';

import { NotificationList } from '~/components';
import { AchRepository, AchReturnCodes, AchReturnCodesType } from '~/repositories';
import { useModalStore, ModalType } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { Headline, FormElement, FormLabel } from '~/styles';
import { formatString } from '~/util';

import { Buttons } from './Alert';
import { Wrapper, IconCircle, Text } from './Authenticate';
import { Form } from './Counterparty';

const ReturnWrapper = styled(Wrapper)`
  --modal-width: 300px;
`;

const Fields = styled.div`
  display: grid;
  grid-gap: 16px;
`;

export const ModalReturn: React.FC = () => {
  const { closeModal, getModalData } = useModalStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const modalTypes: ModalType[] = ['Return'];
  const [returnCode, setReturnCode] = useState<AchReturnCodesType>(
    Object.keys(AchReturnCodes)[0] as AchReturnCodesType
  );
  const [description, setDescription] = useState<string>('');
  const [addenda, setAddenda] = useState<string>('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    AchRepository.createReturn(getModalData()?.achTransferId, {
      returnCode: returnCode,
      description: description,
      addenda: addenda,
      ignoreDeadline: false,
    })
      .then(() => {
        closeModal();
        addSuccessNotification({
          content: 'Return initiated',
          display: 'page',
        });
        if (getModalData()?.callback) {
          getModalData()?.callback();
        }
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
          display: 'popup',
        });
      });
  };

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();

    closeModal('Return');
  };

  return (
    <ReturnWrapper modalTypes={modalTypes}>
      <IconCircle>
        <Icon.AnimationMoney />
      </IconCircle>
      <Text>
        <Headline fullWidth size="small">
          Create return
        </Headline>
      </Text>
      <NotificationList display="popup" />
      <Form onSubmit={handleSubmit}>
        <Fields>
          <FormElement>
            <FormLabel>Return code</FormLabel>
            <Dropdown
              onChange={setReturnCode}
              active={returnCode}
              options={Object.keys(AchReturnCodes).map((code: string) => ({
                label: formatString(code),
                value: code,
              }))}
              fullWidth
              variant="muted"
            />
          </FormElement>
          <FormElement>
            <FormLabel>Description</FormLabel>
            <Textarea onChange={setDescription} value={description} placeholder="Description" />
          </FormElement>
          <FormElement>
            <FormLabel>Addenda</FormLabel>
            <Input onChange={setAddenda} value={addenda} placeholder="Addenda" />
          </FormElement>
        </Fields>
        <Buttons grow>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button>Create</Button>
        </Buttons>
      </Form>
    </ReturnWrapper>
  );
};
