import React, { FC, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { ToggleHeight } from '@column/column-ui-kit';

import { Notification } from '~/components';
import { LogoLoading } from '~/elements';

interface ComponentLoaderProps {
  path: string;
  onLoaded?: () => void;
}

export interface ComponentLoaderTypeProps {
  onLoaded: () => void;
}

const Wrapper = styled.div`
  position: relative;
  transition: min-height 0.3s;
  min-height: 200px;
`;

const Spinner = styled(LogoLoading)<{ $isShow: boolean }>`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%) translateZ(0);
  transition: opacity 0.2s;

  ${({ $isShow }) =>
    !$isShow &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

export const ComponentLoader: FC<ComponentLoaderProps> = ({ path, onLoaded }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isComponentLoading, setIsComponentLoading] = useState<boolean>(true);
  const [Component, setComponent] = useState<FC<ComponentLoaderTypeProps> | null>(null);

  const handleComponentLoaded = useCallback(() => {
    setIsComponentLoading(false);
  }, []);

  const isReady = useMemo(() => !isLoading && !isComponentLoading, [isLoading, isComponentLoading]);

  useEffect(() => {
    let isMounted = true;

    setIsComponentLoading(true);

    const loadComponent = async () => {
      setIsLoading(true);
      try {
        const component = (await import(`../TypeContent/${path}.tsx`)).default;

        if (isMounted) {
          setTimeout(() => setIsLoading(false), 200);
          setComponent(() => component);

          if (onLoaded) {
            onLoaded();
          }
        }
      } catch (error) {
        if (isMounted) {
          setIsLoading(false);
        }
        setErrorMessage('Error loading the component, more details at the browser console.');
        console.error('Error:', error);
      }
    };

    loadComponent();

    return () => {
      isMounted = false;
    };
  }, [path]);

  return (
    <Wrapper>
      <Spinner $isShow={!isReady} />
      <ToggleHeight isClose={!isReady}>
        <Suspense fallback={null}>
          <>
            {Component && <Component onLoaded={handleComponentLoaded} />}
            {errorMessage && (
              <Notification color="danger" variant="light">
                {errorMessage}
              </Notification>
            )}
          </>
        </Suspense>
      </ToggleHeight>
    </Wrapper>
  );
};
