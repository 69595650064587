import React, { useEffect, useMemo, useState, FC, PropsWithChildren, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  Button,
  Icon,
  Checkbox,
  Chip,
  Fade,
  Tooltip,
  ToggleHeight,
  AmountInput,
  formatNumber,
  CurrenciesUSD,
  Currencies,
} from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { CopyInput, Notification, PageHeader, SectionHeader } from '~/components';
import { LogoLoading } from '~/elements';
import { useBankAccount, useCounterparty } from '~/hooks';
import {
  AchRepository,
  AchTransfer,
  AchReturn,
  BookTransfer,
  BookRepository,
  SimulateRepository,
  WireRepository,
  returnCodesTooltipMap,
  transferStatusTooltips,
  convertTransferAmount,
  IntlWireRepository,
} from '~/repositories';
import { BankAccountRepository } from '~/repositories/BankAccountRepository';
import { useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import {
  EditBox,
  EditSection,
  EditSectionTitle,
  EditToolbar,
  Inner,
  FormElement,
  FormText,
  FormLabel,
  Line,
  Grid,
} from '~/styles';
import 'highlight.js/styles/github.css';
import { formatString, generateFields, isValidDate, getDateLongUTC } from '~/util';

import { TransferAmount } from './Overview';

interface Params {
  id: string;
  type: 'ach' | 'wire' | 'book' | 'intl-wire';
}

export const AmountInfo = styled.div`
  display: grid;
  grid-gap: 8px;
  flex: 1;
`;

export const Overdraft = styled.div`
  display: flex;
  grid-gap: 10px;
  padding: 11px 16px 11px 11px;
  border-radius: 8px;
  line-height: 18px;
  font-size: 14px;
  font-weight: 500;
  justify-self: start;
  align-self: end;
  color: ${({ theme }) => theme.secondary.background};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(150)};
`;

export const AmountSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  gap: 24px;

  ${FormElement} {
    flex: 1;
  }
`;

export const AmountText = styled(TransferAmount)<{ size?: number }>`
  font-size: ${(props) => props.size || '24'}px;
  line-height: 24px;
  font-weight: 600;
  position: relative;
`;

const Tags = styled.div`
  display: flex;
  gap: 12px;
`;

export const TransferSummary = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  background: ${({ theme }) => theme.body};
  border-radius: 8px;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.secondary.blendToBackground(75)};
  margin-bottom: 24px;
`;

export const TransferObjectWrapper = styled.div``;

export const Timeline = styled.div`
  display: flex;
  align-items: stretch;
  gap: 16px;
  padding-bottom: 24px;
`;

export const TimelineEntry = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  flex-grow: 1;
  position: relative;

  &:not(:last-child) {
    &:after {
      content: '';
      background-color: ${({ theme }) => theme.secondary.blendToBackground(150)};
      height: 2px;
      position: absolute;
      top: 9px;
      left: 28px;
      right: 0;
      border-radius: 1px;
    }
  }

  svg {
    --icon-color: ${({ theme }) => theme.primary.background};
  }
`;

export const TimelineEntryLabel = styled.div`
  line-height: 18px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.background};
  margin-bottom: 4px;
`;

export const TimelineEntryText = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

export const ReturnTimelineEntryIcon = styled(Icon.CircleCheck)`
  --icon-color: ${({ theme }) => theme.danger.background} !important;
  margin-bottom: 8px;
`;

export const ReturnTimelineEntryDate = styled.div`
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  font-size: 12px;
  font-weight: 500;
`;

const ReturnTimelineEntryDescription = styled.div`
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  font-size: 12px;
  font-weight: 500;
`;

const ReturnTimelineEntryAddenda = styled.div`
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  font-size: 12px;
  font-weight: 500;

  span {
    color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }
`;

const StyledFade = styled(Fade)`
  margin-top: 10%;
`;

export const AccountInformation = styled.div<{ variant?: 'secondary'; padding?: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 24px;
`;

const SandboxWarning = styled.div`
  display: grid;
  grid-column: span 2;

  > div {
    align-self: end;
    padding: 0;
  }
`;

const CounterpartyColumn = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  gap: 24px;
`;

const CounterpartyNotification = styled.div`
  margin-top: auto;
`;

export const TransferDetailParam = styled.label`
  flex: 1;
  white-space: nowrap;
  font-weight: 500;
  font-size: 14px;
  min-height: 40px;
  display: flex;
  align-items: center;
`;

export const Arrow = styled(Icon.ArrowRight)<{ variant?: 'right' | 'left' }>`
  --icon-size: 16px;
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  position: absolute;
  top: 16px;
  right: -12px;
  background: ${({ theme }) => theme.background};
  border: 8px solid ${({ theme }) => theme.background};
  border-radius: 50%;
  border: 1px solid #8a8c9140;
  padding: 2px;

  ${({ variant }) =>
    variant === 'left' &&
    css`
      right: -11px;
      transform: rotate(180deg);
    `}
`;

export const TransferSource = styled.div`
  position: relative;
  display: grid;
  gap: 24px;
  padding-right: 24px;
  border-right: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
`;

export const TransferParty = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

export const PartyName = styled.h4`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 480px;
  overflow: hidden;

  a {
    color: ${({ theme }) => theme.primary.background};
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 480px;
    overflow: hidden;
    display: block;
  }
`;

export const PartyFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TransferDetailValue = styled(CopyInput)`
  box-shadow: none;
  width: 100%;
  max-width: 50%;
  background: transparent;

  input {
    mask-image: linear-gradient(90deg, rgb(0, 0, 0) 92.5%, transparent 100%);
  }
`;

export const TransferDetails = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  gap: 0;

  ul {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(150)};
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 16px;

    li {
      margin: 0;
      padding-left: 16px;
      background: ${({ theme }) =>
        theme.id !== 'dark' ? theme.secondary.blendToBackground(25) : theme.secondary.blendToBackground(5)};
      border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};

      &:first-child {
        border-top: 0;
        border-radius: 0 0 8px 8px;
      }

      ${TransferDetailValue} {
        padding-left: 8px;
      }
    }
  }
`;

export const TransferDetailItem = styled.li<{ parent?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
  padding: 4px 0;
  position: relative;
  z-index: 1;

  ${({ parent }) =>
    parent === true &&
    css`
      flex-direction: column;
      align-items: flex-start;
      padding: 4px 0;
      margin: 4px 0 0;

      ${TransferDetails} {
        width: 100%;
        background: ${({ theme }) => theme.secondary.blendToBackground(25)};
        border: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
        border-radius: 8px;

        > div {
          padding-left: 24px;
        }
      }
    `}

  &:first-child {
    border-top: 0;
  }
`;

export interface TimelineEntryProps {
  field: string;
  label: string;
  type?: 'success' | 'danger';
}

const achTypes: TimelineEntryProps[] = [
  {
    label: 'Created',
    field: 'createdAt',
  },
  {
    label: 'Submitted',
    field: 'submittedAt',
  },
  {
    label: 'Acknowledged',
    field: 'acknowledgedAt',
  },
  {
    label: 'Processed',
    field: 'processedAt',
  },
  {
    label: 'Settled',
    field: 'settledAt',
  },
  {
    label: 'Rejected',
    field: 'rejectedAt',
    type: 'danger',
  },
  {
    label: 'Cancelled',
    field: 'cancelledAt',
    type: 'danger',
  },
  {
    label: 'Returned',
    field: 'returnedAt',
    type: 'danger',
  },
  {
    label: 'Completed',
    field: 'completedAt',
    type: 'success',
  },
];

const wireTypes: TimelineEntryProps[] = [
  {
    label: 'Created',
    field: 'createdAt',
  },
  {
    label: 'Initiated',
    field: 'initiatedAt',
  },
  {
    label: 'Pending Submission',
    field: 'pendingSubmissionAt',
  },
  {
    label: 'Submitted',
    field: 'submittedAt',
  },
  {
    label: 'Completed',
    field: 'completedAt',
    type: 'success',
  },
  {
    label: 'Rejected',
    field: 'rejectedAt',
    type: 'danger',
  },
];

const swiftTypes: TimelineEntryProps[] = [
  {
    label: 'Created',
    field: 'createdAt',
  },
  {
    label: 'Initiated',
    field: 'initiatedAt',
  },
  {
    label: 'Pending Submission',
    field: 'pendingSubmissionAt',
  },
  {
    label: 'Submitted',
    field: 'submittedAt',
  },
  {
    label: 'Manual Review',
    field: 'manualReviewAt',
  },
  {
    label: 'Completed',
    field: 'completedAt',
    type: 'success',
  },
  {
    label: 'Returned',
    field: 'returnedAt',
    type: 'danger',
  },
];

const TransferTypeMap = new Map<string, string>([
  ['ach', 'ACH'],
  ['book', 'Book'],
  ['intl-wire', 'International Wire'],
  ['wire', 'Wire'],
]);

export const TimelineArea: React.FC<{ entry: TimelineEntryProps; transfer: any }> = (props) => {
  const date = props.transfer[props.entry.field] as Date;
  const icon = props.entry.type === 'danger' ? <Icon.CircleCross /> : <Icon.CircleCheck />;
  if (!date) {
    return null;
  }
  return (
    <TimelineEntry key={props.entry.field}>
      {icon}
      <TimelineEntryText>
        <TimelineEntryLabel>{props.entry.label}</TimelineEntryLabel>
        {date.toLocaleString('en-US', { weekday: 'long' })}, {date.toLocaleString('en-US', { month: 'long' })}{' '}
        {date.getDate()}, {date.getFullYear()} <br />
        {date.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}
      </TimelineEntryText>
    </TimelineEntry>
  );
};

export const ReturnSection: React.FC<{ achReturn: DeepPartial<AchReturn> }> = (props) => {
  const createdAt = new Date(props.achReturn.createdAt?.toString() ?? '');

  return (
    <>
      <SectionHeader text="Return" border />
      <Inner pt={0}>
        <Timeline>
          {props.achReturn.details?.map((detail: any) => {
            const detailDate = new Date(detail.createdAt);

            return (
              <TimelineEntry key={detail.status}>
                <ReturnTimelineEntryIcon />
                <TimelineEntryText>
                  <TimelineEntryLabel>{formatString(detail.status)}</TimelineEntryLabel>
                  {detail.returnCode && (
                    <Chip
                      size="small"
                      tooltip={
                        returnCodesTooltipMap.has(detail.returnCode)
                          ? {
                              content: returnCodesTooltipMap.get(detail.returnCode),
                              delay: 200,
                              triggerClick: false,
                            }
                          : undefined
                      }
                    >
                      {detail.returnCode}
                    </Chip>
                  )}
                </TimelineEntryText>
                <ReturnTimelineEntryDate>
                  {detailDate.getDate()} {detailDate.toLocaleString('en-US', { month: 'long' })}{' '}
                  {detailDate.getFullYear()}, {detailDate.toLocaleString('en-US', { weekday: 'long' })} -{' '}
                  {detailDate.toLocaleString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                  })}
                </ReturnTimelineEntryDate>
                {detail.description && (
                  <ReturnTimelineEntryDescription>{detail.description}</ReturnTimelineEntryDescription>
                )}
                {detail.addenda && (
                  <ReturnTimelineEntryAddenda>
                    <span>Addenda:</span> {detail.addenda}
                  </ReturnTimelineEntryAddenda>
                )}
              </TimelineEntry>
            );
          })}
        </Timeline>
        <Line />
      </Inner>
      <Inner pt={0}>
        <Grid>
          <FormElement>
            <FormLabel>Created</FormLabel>
            <FormText>
              {createdAt.toLocaleString('en-US', { weekday: 'long' })},{' '}
              {createdAt.toLocaleString('en-US', { month: 'long' })} {createdAt.getDate()}, {createdAt.getFullYear()}
              <br />
              {createdAt.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}
            </FormText>
          </FormElement>
          <FormElement>
            <FormLabel>Status</FormLabel>
            <FormText>
              <Chip>{formatString(props.achReturn.status ?? '-')}</Chip>
            </FormText>
          </FormElement>
          <FormElement>
            <FormLabel>Incoming</FormLabel>
            <FormText>
              <Chip icon={props.achReturn.isIncoming ? <Icon.CircleArrowDown /> : <Icon.CircleArrowUp />}>
                {props.achReturn.isIncoming ? 'True' : 'False'}
              </Chip>
            </FormText>
          </FormElement>
        </Grid>
      </Inner>
    </>
  );
};

const formatLabel = (content: string): string => {
  const exceptions: { [key: string]: string } = {
    id: 'ID',
    micr: 'MICR',
    fi: 'FI',
    di: 'DI',
    imad: 'IMAD',
    uetr: 'UETR',
    fx: 'FX',
    fis: 'FIS',
    omad: 'OMAD',
    line1: 'Line 1',
    line2: 'Line 2',
    line3: 'Line 3',
    line4: 'Line 4',
    line5: 'Line 5',
    line6: 'Line 6',
  };

  const words = content
    .replace(/([A-Z])/g, ' $1')
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return exceptions[word] || word;
    });

  return words
    .join(' ')
    .replace(/\b\w/g, (s) => s.toUpperCase())
    .trim();
};

export const TransferObject: FC<PropsWithChildren<object>> = ({ children }) => (
  <TransferDetails>{children}</TransferDetails>
);

export const ElementRow: FC<{ label: string; content?: string; parent?: boolean }> = ({ label, content, parent }) => (
  <TransferDetailItem parent={parent}>
    <TransferDetailParam>{formatLabel(label)}</TransferDetailParam>
    {typeof content !== 'undefined' && <TransferDetailValue value={content} />}
  </TransferDetailItem>
);

export const loopObject = (obj: Record<string, any>, parent?: string) => {
  const elements = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (Array.isArray(obj[key]) && obj[key].length === 0) {
        elements.push(<ElementRow key={parent ? `${key}-${parent}` : key} label={key} content="[]" />);
      } else if (isValidDate(obj[key])) {
        elements.push(
          <ElementRow key={parent ? `${key}-${parent}` : key} label={key} content={getDateLongUTC(obj[key])} />
        );
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        elements.push(
          <Fragment key={parent ? `${key}-${parent}` : key}>
            <ElementRow label={key} parent />
            <TransferObject>{loopObject(obj[key], key)}</TransferObject>
          </Fragment>
        );
      } else if (typeof obj[key] === 'boolean') {
        elements.push(<ElementRow key={parent ? `${key}-${parent}` : key} label={key} content={obj[key].toString()} />);
      } else {
        elements.push(<ElementRow key={parent ? `${key}-${parent}` : key} label={key} content={obj[key] ?? 'null'} />);
      }
    }
  }
  return elements;
};

//

export const PageTransfersView: React.FC = () => {
  const { isSandbox, currentUser } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const openModal = useModalStore((state) => state.openModal);
  const navigate = useNavigate();
  const { id, type } = useParams<keyof Params>() as Params;

  const [transfer, setTransfer] = useState<any>({});
  const [isTransferLoading, setTransferIsLoading] = useState<boolean>(true);

  const [accountNumber, setAccountNumber] = useState<any>({});
  const [isAccountNumberLoading, setIsAccountNumberLoading] = useState<boolean>(true);

  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [currencyCode, setCurrencyCode] = useState<string>('USD');
  const [allowOverdraft, setAllowOverdraft] = useState<boolean>(false);
  const [ach, setAch] = useState<any>({});
  const [achReturn, setAchReturn] = useState<DeepPartial<AchReturn> | null>(null);

  const { response: bankAccount, createRequest: fetchBankAccount } = useBankAccount({
    initialParams: transfer.bankAccountId ?? transfer.senderBankAccountId,
    onError: (error) => {
      addDangerNotification({
        content: error.message,
        display: 'page',
      });
    },
  });

  const { response: receiverBankAccount, createRequest: fetchReceiverBankAccount } = useBankAccount({
    initialParams: transfer?.receiverBankAccountId,
    onError: (error) => {
      addDangerNotification({
        content: error.message,
        display: 'page',
      });
    },
  });

  const {
    response: counterparty,
    createRequest: fetchCounterparty,
    isLoading: isCounterpartyLoading,
  } = useCounterparty({
    initialParams: transfer?.counterpartyId,
    onError: (error) => {
      addDangerNotification({
        content: error.message,
        display: 'page',
      });
    },
  });

  const isLoading = useMemo(() => {
    if (
      isTransferLoading ||
      Object.keys(transfer).length < 1 ||
      (transfer?.accountNumberId && isAccountNumberLoading) ||
      (transfer?.counterpartyId && isCounterpartyLoading)
    ) {
      return true;
    }

    return false;
  }, [transfer, isTransferLoading, isCounterpartyLoading, isAccountNumberLoading]);

  const handlePromiseError = (promise: any, callback: (response: any) => void) => {
    return promise
      .then((response: any) => {
        callback(response);
      })
      .catch((error: any) => {
        navigate(ROUTE.TRANSFERS);
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const events =
    type === 'ach' ? achTypes : type === 'wire' ? wireTypes : type === 'intl-wire' ? swiftTypes : undefined;

  const handleSettle = () => {
    if (!id) {
      return;
    }
    if (type === 'ach') {
      SimulateRepository.settleACH({ achTransferId: id })
        .then(() => {
          fetchTransfer();
          addSuccessNotification({
            content: 'ACH transfer settled',
            display: 'page',
          });
        })
        .catch((error: any) => {
          addDangerNotification({
            content: error.message,
            display: 'page',
          });
        });
      return;
    }
    SimulateRepository.settleWire({ wireTransferId: id })
      .then(() => {
        fetchTransfer();
        addSuccessNotification({
          content: 'Wire transfer settled',
          display: 'page',
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const handleCancel = () => {
    if (!id || type !== 'ach') {
      return;
    }
    AchRepository.cancel(id)
      .then(() => {
        fetchTransfer();
        addSuccessNotification({
          content: 'ACH transfer cancelled',
          display: 'page',
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const handleCancelBook = () => {
    if (!id || type !== 'book') {
      return;
    }
    BookRepository.cancel(id)
      .then(() => {
        fetchTransfer();
        addSuccessNotification({
          content: 'Book transfer cancelled',
          display: 'page',
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const handleSubmit = () => {
    BookRepository.update(id, { amount: (amount || 0).toString(), currencyCode, allowOverdraft })
      .then(() => {
        addSuccessNotification({
          content: 'Transfer updated',
          display: 'page',
        });
        fetchTransfer();
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const fetchAccountNumber = (accountNumberId?: string) => {
    if (!accountNumberId) {
      return;
    }

    setIsAccountNumberLoading(true);

    handlePromiseError(BankAccountRepository.getAccountNumber({ id: accountNumberId }), setAccountNumber).finally(
      () => {
        setIsAccountNumberLoading(false);
      }
    );
  };

  const fetchTransfer = () => {
    setTransferIsLoading(true);

    if (type === 'ach') {
      handlePromiseError(AchRepository.get({ id }), (response: AchTransfer) => {
        setTransfer(response);
        setAch(response);

        fetchAccountNumber(response.accountNumberId);

        if (response?.bankAccountId) {
          fetchBankAccount({ id: response.bankAccountId });
        }

        if (response?.counterpartyId) {
          fetchCounterparty({ id: response.counterpartyId });
        }

        setTransferIsLoading(false);

        if (
          response?.status &&
          ['RETURNED', 'RETURN_CONTESTED', 'RETURN_DISHONORED', 'PENDING_RETURN'].includes(response.status)
        ) {
          AchRepository.getReturn(id)
            .then(setAchReturn)
            .catch(() => null);
        }
      });
      return;
    }

    if (type === 'wire') {
      handlePromiseError(WireRepository.get({ id }), (response) => {
        setTransfer(response);

        if (response?.counterpartyId) {
          fetchCounterparty({ id: response.counterpartyId });
        }

        fetchAccountNumber(response.accountNumberId ?? response.senderAccountNumberId);
      });

      setTransferIsLoading(false);

      return;
    }

    if (type === 'intl-wire') {
      handlePromiseError(IntlWireRepository.get({ id }), (response) => {
        setTransfer(response);

        if (response?.counterpartyId) {
          fetchCounterparty({ id: response.counterpartyId });
        }

        fetchAccountNumber(response.accountNumberId ?? response.senderAccountNumberId);

        setTransferIsLoading(false);
      });
      return;
    }

    if (type === 'book') {
      handlePromiseError(BookRepository.get({ id }), (response) => {
        setTransfer(response);
        setAmount(response.amount);
        setCurrencyCode(response.currencyCode);
        setAllowOverdraft(response.allowOverdraft);
        fetchAccountNumber(response.accountNumberId ?? response.senderAccountNumberId);

        if (response?.senderBankAccountId) {
          fetchBankAccount({ id: response.senderBankAccountId });
        }

        if (response?.receiverBankAccountId) {
          fetchReceiverBankAccount({ id: response.receiverBankAccountId });
        }

        setTransferIsLoading(false);
      });
      return;
    }
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    if (id) {
      fetchTransfer();
    }
  }, [id]);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isSandbox: state.isSandbox,
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => {
          if (!currentUser) {
            return;
          }
          if (id) {
            fetchTransfer();
          }
        }
      ),
    []
  );
  const counterpartyDescription =
    counterparty?.description ?? counterparty?.name ?? counterparty?.wire?.beneficiaryName ?? 'empty';

  const convertAmount = convertTransferAmount(transfer?.type, transfer?.isIncoming);

  return (
    <>
      <StyledFade show={isLoading}>
        <LogoLoading />
      </StyledFade>
      <Fade show={!isLoading}>
        <PageHeader text={TransferTypeMap.get(type) || 'Transfer'}>
          {type === 'book' && transfer.status === 'HOLD' && (
            <>
              <Button
                size="small"
                onClick={() =>
                  openModal('ClearTransfer', {
                    transferId: id,
                    callback: fetchTransfer,
                  })
                }
              >
                Clear
              </Button>
              <Button variant="danger" size="small" onClick={handleCancelBook} icon={<Icon.CircleCross />}>
                Cancel
              </Button>
            </>
          )}
          {type === 'ach' && ['INITIATED', 'PENDING_SUBMISSION'].includes(transfer.status) && (
            <Button variant="danger" size="small" onClick={handleCancel} icon={<Icon.CircleCross />}>
              Cancel
            </Button>
          )}
          {type === 'ach' && !transfer.returnedAt && transfer.isIncoming && (
            <Button
              variant="danger"
              size="small"
              onClick={() =>
                openModal('Return', {
                  achTransferId: id,
                  callback: fetchTransfer,
                })
              }
              icon={<Icon.MoneyReturn />}
            >
              Return
            </Button>
          )}
        </PageHeader>

        <AmountSummary>
          <AmountInfo>
            <AmountText>{formatNumber(transfer?.amount)}</AmountText>
            <Tags>
              {transfer.status && (
                <Chip
                  tooltip={
                    transferStatusTooltips.has(String(transfer.status).toLowerCase())
                      ? {
                          content: transferStatusTooltips.get(String(transfer.status).toLowerCase()),
                          delay: 200,
                          triggerClick: false,
                        }
                      : undefined
                  }
                  type={
                    transfer.status === 'HOLD'
                      ? 'info'
                      : ['REJECTED', 'CANCELED', 'CANCELLED', 'RETURNED'].includes(transfer.status)
                        ? 'danger'
                        : ['SETTLED', 'COMPLETED', 'SUBMITTED'].includes(transfer.status)
                          ? 'success'
                          : 'default'
                  }
                >
                  {formatString(transfer.status)}
                </Chip>
              )}
              {type === 'book' ? (
                <Chip icon={<Icon.Swap />}>Internal</Chip>
              ) : (
                <>
                  {transfer.isIncoming ? (
                    <Chip icon={<Icon.CircleArrowDown />}>Incoming</Chip>
                  ) : (
                    <Chip icon={<Icon.CircleArrowUp />}>Outgoing</Chip>
                  )}
                </>
              )}
              <Chip>Overdraft {transfer.allowOverdraft ? 'allowed' : 'disallowed'}</Chip>
            </Tags>
          </AmountInfo>
          <FormElement>
            <FormLabel>ID</FormLabel>
            <CopyInput value={id} />
          </FormElement>
        </AmountSummary>

        {type === 'intl-wire' && (
          <>
            <Line />
            <Grid gap={0}>
              <div>
                <SectionHeader
                  text="Instructed Amount"
                  tooltip={
                    <>
                      <Tooltip
                        content={
                          transfer.isIncoming
                            ? 'Amount & currency ordered by the originator, before deduction of charges.'
                            : 'Amount & currency specified in your API requests.'
                        }
                      >
                        <Icon.CircleInfo />
                      </Tooltip>
                    </>
                  }
                />
                <Inner pt={0}>
                  <AmountInfo>
                    <AmountText size={20}>
                      {transfer?.instructedAmount
                        ? formatNumber(transfer.instructedAmount, transfer?.instructedCurrencyCode ?? 'USD')
                        : '-'}
                    </AmountText>
                    <Tags>
                      {transfer?.instructedCurrencyCode && (
                        <>
                          <Chip>{Currencies.find((c) => c.code === transfer.instructedCurrencyCode)?.name}</Chip>
                          <Chip>{transfer.instructedCurrencyCode}</Chip>
                        </>
                      )}
                    </Tags>
                  </AmountInfo>
                </Inner>
              </div>

              <div>
                <SectionHeader
                  flush
                  text="Settled Amount"
                  tooltip={
                    <>
                      <Tooltip
                        content={
                          transfer.isIncoming
                            ? 'Amount & currency received from the beneficiary bank by Column.'
                            : 'Amount & currency sent to the beneficiary bank by Column.'
                        }
                      >
                        <Icon.CircleInfo />
                      </Tooltip>
                    </>
                  }
                />
                <Inner px={0} pt={0}>
                  <AmountInfo>
                    <AmountText size={20}>
                      {transfer?.settledAmount
                        ? formatNumber(transfer.settledAmount, transfer?.settledCurrencyCode ?? 'USD')
                        : '-'}
                    </AmountText>
                    <Tags>
                      {transfer?.settledCurrencyCode && (
                        <>
                          <Chip>{Currencies.find((c) => c.code === transfer.settledCurrencyCode)?.name}</Chip>
                          <Chip>{transfer.settledCurrencyCode}</Chip>
                        </>
                      )}
                      {transfer?.settlementDate && <Chip>{transfer.settlementDate}</Chip>}
                    </Tags>
                  </AmountInfo>
                </Inner>
              </div>
            </Grid>
          </>
        )}

        <ToggleHeight isClose={!achReturn}>{achReturn && <ReturnSection achReturn={achReturn} />}</ToggleHeight>

        <SectionHeader text="Information" border />
        <Inner py={0}>
          <TransferSummary>
            <TransferSource>
              {transfer.accountNumberId || transfer.senderAccountNumberId ? (
                <>
                  <TransferParty>
                    <Label>Bank Account</Label>
                    <PartyName>
                      <a
                        href={`${ROUTE.BANK_ACCOUNTS}/edit/${
                          bankAccount?.id ? bankAccount.id : transfer.bankAccountId
                        }`}
                        title={
                          bankAccount?.displayName ? bankAccount?.displayName : (accountNumber.description ?? undefined)
                        }
                      >
                        {bankAccount?.displayName ? bankAccount?.displayName : (accountNumber.description ?? undefined)}
                      </a>
                    </PartyName>
                  </TransferParty>

                  <FormElement newRow>
                    <FormLabel>Bank Account ID</FormLabel>
                    <CopyInput value={transfer.bankAccountId ?? transfer.senderBankAccountId} />
                  </FormElement>

                  {accountNumber.routingNumber && accountNumber.accountNumber && (
                    <AccountInformation>
                      <FormElement>
                        <FormLabel>Routing Number</FormLabel>
                        <CopyInput value={accountNumber.routingNumber} />
                      </FormElement>
                      <FormElement>
                        <FormLabel>Account Number</FormLabel>
                        <CopyInput value={accountNumber.accountNumber} />
                      </FormElement>

                      {isSandbox && (
                        <SandboxWarning>
                          <Notification color="warning" withClose={false} variant="light">
                            Account numbers created in sandbox mode cannot be used to receive funds.
                          </Notification>
                        </SandboxWarning>
                      )}
                    </AccountInformation>
                  )}
                </>
              ) : (
                <FormElement newRow>
                  <FormLabel>Bank Account ID</FormLabel>
                  <CopyInput value={transfer.bankAccountId ?? transfer.senderBankAccountId} />
                </FormElement>
              )}
              {transfer.isIncoming ? <Arrow variant="left" /> : <Arrow />}
            </TransferSource>

            {receiverBankAccount?.description && transfer.receiverBankAccountId ? (
              <PartyFields>
                <TransferParty>
                  <Label>Bank Account</Label>
                  <PartyName>
                    <a href={`${ROUTE.BANK_ACCOUNTS}/edit/${transfer.receiverBankAccountId}`}>
                      {receiverBankAccount?.displayName
                        ? receiverBankAccount?.displayName
                        : (receiverBankAccount.description ?? undefined)}
                    </a>
                  </PartyName>
                </TransferParty>

                <FormElement>
                  <FormLabel>Receiver Bank Account ID</FormLabel>
                  <CopyInput value={transfer.receiverBankAccountId} />
                </FormElement>
              </PartyFields>
            ) : (
              <CounterpartyColumn>
                <TransferParty>
                  <Label>{counterpartyDescription ? 'Counterparty' : 'Company Name'}</Label>
                  <PartyName>
                    {transfer.counterpartyId ? (
                      <a href={`${ROUTE.COUNTERPARTIES}/edit/${transfer.counterpartyId}`}>{counterpartyDescription}</a>
                    ) : (
                      transfer.companyName
                    )}
                  </PartyName>
                </TransferParty>

                {transfer.counterpartyId && (
                  <FormElement>
                    <FormLabel>Counterparty ID</FormLabel>
                    <CopyInput value={transfer.counterpartyId} />
                  </FormElement>
                )}

                {counterparty?.routingNumber && counterparty?.accountNumber && (
                  <AccountInformation>
                    <FormElement>
                      <FormLabel>Routing Number</FormLabel>
                      <CopyInput value={counterparty.routingNumber} />
                    </FormElement>
                    <FormElement>
                      <FormLabel>Account Number</FormLabel>
                      <CopyInput value={counterparty.accountNumber} />
                    </FormElement>
                  </AccountInformation>
                )}
                {!counterparty && !isCounterpartyLoading && (
                  <CounterpartyNotification>
                    <Notification color="danger" withClose={false} variant="light">
                      No counterparty found with the ID "{transfer.counterpartyId}".
                    </Notification>
                  </CounterpartyNotification>
                )}
              </CounterpartyColumn>
            )}
            <FormElement fullWidth as={Line} />
            {!!transfer &&
              generateFields<BookTransfer>(
                [
                  {
                    type: 'text',
                    label: 'Description',
                    value: 'description',
                    newRow: true,
                  },
                  {
                    type: 'text',
                    label: 'Idempotency Key',
                    value: 'idempotencyKey',
                  },
                ],
                transfer,
                setTransfer
              )}
            {type === 'intl-wire' && (
              <>
                <FormElement>
                  <FormLabel>Foreign exchange rate</FormLabel>
                  <CopyInput value={transfer?.fxRate ?? '-'} />
                </FormElement>
                <FormElement>
                  <FormLabel>Foreign exchange quote ID</FormLabel>
                  <CopyInput value={transfer?.fxQuoteId ?? '-'} />
                </FormElement>
                <FormElement fullWidth as={Line} />
                <FormElement>
                  <FormLabel>Instruction to beneficiary's financial institution</FormLabel>
                  <FormText>{transfer.instructionToBeneficiaryFi ?? '-'}</FormText>
                </FormElement>
                <FormElement>
                  <FormLabel>Charge bearer</FormLabel>
                  <FormText>
                    <Chip>{transfer.chargeBearer ?? '-'}</Chip>
                  </FormText>
                </FormElement>
                <FormElement>
                  <FormLabel>UETR (Unique End-to-End Transaction Reference)</FormLabel>
                  <CopyInput value={transfer?.uetr ?? '-'} />
                </FormElement>
              </>
            )}
          </TransferSummary>
        </Inner>

        <ToggleHeight isClose={!events}>
          <SectionHeader text="Events" border>
            {(type === 'ach' || type === 'wire') &&
              isSandbox &&
              !['COMPLETED', 'REJECTED', 'SETTLED', 'CANCELED', 'RETURNED'].includes(transfer.status) && (
                <Button onClick={handleSettle} variant="primary" size="small" icon={<Icon.Sandbox />}>
                  Settle
                </Button>
              )}
          </SectionHeader>
          <Inner pt={16}>
            <Timeline>
              {events && events.map((entry) => <TimelineArea key={entry.field} transfer={transfer} entry={entry} />)}
            </Timeline>
          </Inner>
        </ToggleHeight>

        <ToggleHeight isClose={type !== 'ach'}>
          <SectionHeader text="ACH" border />
          <Inner pt={16}>
            <Grid>
              <FormElement>
                <FormLabel>Type</FormLabel>
                <FormText>
                  <Chip>{ach.type ?? '-'}</Chip>
                </FormText>
              </FormElement>
              <FormElement newRow>
                <FormLabel>Effective Date</FormLabel>
                <FormText>{getDateLongUTC(new Date(ach.effectiveOn))}</FormText>
              </FormElement>
              {!!transfer &&
                generateFields<AchTransfer>(
                  [
                    {
                      type: 'chip',
                      label: 'Entry Class Code',
                      value: 'entryClassCode',
                      newRow: true,
                    },
                    {
                      type: 'text',
                      label: 'Payment Related Info',
                      value: 'paymentRelatedInfo',
                    },
                  ],
                  ach,
                  setAch
                )}
            </Grid>
          </Inner>

          <SectionHeader text="Company" border />
          <Inner pt={16}>
            <Grid>
              {!!transfer &&
                generateFields<AchTransfer>(
                  [
                    {
                      type: 'text',
                      label: 'Company ID',
                      value: 'companyId',
                    },
                    {
                      type: 'text',
                      label: 'Company Name',
                      value: 'companyName',
                      newRow: true,
                    },
                    {
                      type: 'text',
                      label: 'Company Entry Description',
                      value: 'companyEntryDescription',
                    },
                    {
                      type: 'text',
                      label: 'Company Discretionary Data',
                      value: 'companyDiscretionaryData',
                    },
                  ],
                  ach,
                  setAch
                )}
            </Grid>
          </Inner>

          <SectionHeader text="Receiver" border />
          <Inner pt={16}>
            <Grid>
              {!!transfer &&
                generateFields<AchTransfer>(
                  [
                    {
                      type: 'text',
                      label: 'Receiver Name',
                      value: 'receiverName',
                      newRow: true,
                    },
                    {
                      type: 'text',
                      label: 'Receiver ID',
                      value: 'receiverId',
                    },
                  ],
                  ach,
                  setAch
                )}
            </Grid>
          </Inner>
        </ToggleHeight>
        {type === 'book' && transfer.status === 'HOLD' && (
          <EditSection>
            <EditSectionTitle>Update Holding Transfer</EditSectionTitle>
            <EditBox>
              <FormElement>
                <FormLabel>Amount</FormLabel>
                <AmountInput
                  onChange={setAmount}
                  value={amount}
                  onCurrencyCodeChange={(value: string) => setCurrencyCode(value)}
                  currencyCode={currencyCode}
                  currencyList={CurrenciesUSD}
                />
              </FormElement>
              <Overdraft>
                <Checkbox isChecked={allowOverdraft} onCheckedChange={(value: boolean) => setAllowOverdraft(value)} />
                Allow Overdraft
              </Overdraft>
            </EditBox>
          </EditSection>
        )}
        {type === 'intl-wire' && (
          <>
            <SectionHeader text="Originator Information" border />
            <Inner pt={16}>
              <Grid>
                <FormElement fullWidth>
                  <FormLabel>Name</FormLabel>
                  <FormText>{transfer?.originatorName ?? '-'}</FormText>
                </FormElement>
                <FormElement>
                  <FormLabel>Account Number</FormLabel>
                  <CopyInput value={transfer?.originatorAccountNumber ?? '-'} />
                </FormElement>
                <FormElement>
                  <FormLabel>Financial Institution (Bank Code)</FormLabel>
                  <CopyInput value={transfer?.originatorFi ?? '-'} />
                </FormElement>
              </Grid>
            </Inner>

            <Inner pt={16}>
              <SectionHeader text="Address" border flush />
              <Inner pt={16} px={0}>
                <Grid>
                  <FormElement>
                    <FormLabel>Line 1</FormLabel>
                    <FormText>{transfer.originatorAddress?.line1 ?? '-'}</FormText>
                  </FormElement>
                  <FormElement>
                    <FormLabel>Line 2</FormLabel>
                    <FormText>{transfer.originatorAddress?.line2 ?? '-'}</FormText>
                  </FormElement>
                  <FormElement>
                    <FormLabel>City</FormLabel>
                    <FormText>{transfer.originatorAddress?.city ?? '-'}</FormText>
                  </FormElement>
                  <FormElement>
                    <FormLabel>Country Code</FormLabel>
                    <FormText>{transfer.originatorAddress?.countryCode ?? '-'}</FormText>
                  </FormElement>
                  <FormElement>
                    <FormLabel>Postal Code</FormLabel>
                    <FormText>{transfer.originatorAddress?.postalCode ?? '-'}</FormText>
                  </FormElement>
                  <FormElement>
                    <FormLabel>State</FormLabel>
                    <FormText>{transfer.originatorAddress?.state ?? '-'}</FormText>
                  </FormElement>
                </Grid>
              </Inner>
            </Inner>

            <SectionHeader text="Beneficiary Information" border />
            <Inner pt={16}>
              <Grid>
                <FormElement fullWidth>
                  <FormLabel>Name</FormLabel>
                  <FormText>{transfer?.beneficiaryName ?? '-'}</FormText>
                </FormElement>
                <FormElement>
                  <FormLabel>Account Number</FormLabel>
                  <CopyInput value={transfer?.beneficiaryAccountNumber ?? '-'} />
                </FormElement>
                <FormElement>
                  <FormLabel>Financial Institution (Bank Code)</FormLabel>
                  <CopyInput value={transfer?.beneficiaryFi ?? '-'} />
                </FormElement>
              </Grid>
            </Inner>

            <Inner pt={16}>
              <SectionHeader text="Address" border flush />
              <Inner pt={16} px={0}>
                <Grid>
                  <FormElement>
                    <FormLabel>Line 1</FormLabel>
                    <FormText>{transfer.beneficiaryAddress?.line1 ?? '-'}</FormText>
                  </FormElement>
                  <FormElement>
                    <FormLabel>Line 2</FormLabel>
                    <FormText>{transfer.beneficiaryAddress?.line2 ?? '-'}</FormText>
                  </FormElement>
                  <FormElement>
                    <FormLabel>City</FormLabel>
                    <FormText>{transfer.beneficiaryAddress?.city ?? '-'}</FormText>
                  </FormElement>
                  <FormElement>
                    <FormLabel>Country Code</FormLabel>
                    <FormText>{transfer.beneficiaryAddress?.countryCode ?? '-'}</FormText>
                  </FormElement>
                  <FormElement>
                    <FormLabel>Postal Code</FormLabel>
                    <FormText>{transfer.beneficiaryAddress?.postalCode ?? '-'}</FormText>
                  </FormElement>
                  <FormElement>
                    <FormLabel>State</FormLabel>
                    <FormText>{transfer.beneficiaryAddress?.state ?? '-'}</FormText>
                  </FormElement>
                </Grid>
              </Inner>
            </Inner>
          </>
        )}

        <SectionHeader text="Transfer Details" border />
        <Inner pt={16}>
          <TransferObjectWrapper>
            <TransferObject>{transfer && loopObject(transfer)}</TransferObject>
          </TransferObjectWrapper>
        </Inner>

        <Inner>
          <EditToolbar>
            <Button onClick={() => navigate(-1)} variant="secondary" size="small">
              Back
            </Button>
            {type === 'book' && transfer.status === 'HOLD' && (
              <Button onClick={handleSubmit} size="small">
                Update
              </Button>
            )}
          </EditToolbar>
        </Inner>
      </Fade>
    </>
  );
};
