import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Checkbox, Dropdown } from '@column/column-ui-kit';

import { Form } from '../../Login';
import { Option, OptionList, RegisterAction, RegisterContent, RegisterContentGrid, StyledTextarea } from '../Questions';
import { NotificationList } from '~/components';
import { useNAICSCodes } from '~/hooks';
import { ROUTE } from '~/public/routes';
import { BusinessPageIdType, DepgraphNodeValues } from '~/repositories/PlatformRepository';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { FormElement, FormLabel, Headline, Paragraph } from '~/styles';

import { FormElementHint } from './Location';
import { Countries } from './Partial/Countries';

export const PageRegisterBusinessCustomerTypes: React.FC = () => {
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const currentPlatformState = useSessionStore((state) => state.currentPlatform);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const naicsCodes = useNAICSCodes();
  const { control, handleSubmit, reset } = useForm<DepgraphNodeValues>();

  const [typesOfCustomers, setTypesOfCustomers] = useState<string[]>([]);
  const [locationsOfCustomers, setLocationsOfCustomers] = useState<string[]>(['']);
  const [businessDescription, setBusinessDescription] = useState<string>('');
  const [businessDescriptionError, setBusinessDescriptionError] = useState<boolean>(false);

  const handleCustomerTypesChange = (optionKey: string) => {
    if (typesOfCustomers.includes(optionKey)) {
      setTypesOfCustomers(typesOfCustomers.filter((k) => k !== optionKey));
      return;
    }
    setTypesOfCustomers([...typesOfCustomers, optionKey]);
  };

  const onSuccess = (depgraphNodeValues: DepgraphNodeValues) => {
    if (isLoading) {
      return;
    }

    setBusinessDescriptionError(false);

    if (typesOfCustomers.length < 1) {
      addDangerNotification({
        content: 'Please select at least one option.',
        display: 'customer-types',
      });
      return;
    }

    if (locationsOfCustomers.filter(Boolean).length < 1) {
      addDangerNotification({
        content: 'Please select at least one country.',
        display: 'end-customer-countries',
      });
      return;
    }

    if (businessDescription.length < 1) {
      addDangerNotification({
        content: 'Please add a business description.',
        display: 'business-description',
      });
      setBusinessDescriptionError(true);
      return;
    }

    setIsLoading(true);

    currentPlatformState
      ?.updateDepgraphNodeValues({
        typesOfCustomers,
        locationsOfCustomers,
        businessDescription,
        naicsCode: depgraphNodeValues.naicsCode,
        completedPages: [
          ...new Set<BusinessPageIdType>([
            ...(currentPlatformState.depgraphNodeValues?.completedPages ?? []),
            'your-customers',
          ]),
        ],
      })
      .then(() => {
        navigate(ROUTE.REGISTER_BUSINESS_YOUR_INFORMATION);
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => state.currentPlatform,
        (currentPlatform) => {
          reset(currentPlatform?.depgraphNodeValues);
          setTypesOfCustomers(currentPlatform?.depgraphNodeValues?.typesOfCustomers ?? []);
          setLocationsOfCustomers(currentPlatform?.depgraphNodeValues?.locationsOfCustomers ?? ['']);
          setBusinessDescription(currentPlatform?.depgraphNodeValues?.businessDescription ?? '');
        },
        { fireImmediately: true }
      ),
    [location.pathname]
  );

  return (
    <RegisterContent>
      <RegisterContentGrid>
        <Headline>Your customers</Headline>
        <Paragraph size="small">
          We are also required to ask you a bit more information on your customer base.
        </Paragraph>
      </RegisterContentGrid>
      <Form onSubmit={handleSubmit(onSuccess)}>
        <NotificationList display="page" />
        <RegisterContent>
          <div>
            <NotificationList display="customer-types" />
            <FormElement>
              <FormLabel>Which of the following best describes your end-customers?</FormLabel>
              <FormElementHint>(Select all that apply)</FormElementHint>
              <div>
                <OptionList>
                  {['Consumers/Individuals', 'Legal Entities/Businesses'].map((optionKey: string) => {
                    return (
                      <Option
                        isActive={typesOfCustomers.includes(optionKey)}
                        isSimple
                        key={optionKey}
                        onClick={() => handleCustomerTypesChange(optionKey)}
                      >
                        <Checkbox
                          isChecked={typesOfCustomers.includes(optionKey)}
                          onCheckedChange={() => handleCustomerTypesChange(optionKey)}
                          label={optionKey}
                        />
                      </Option>
                    );
                  })}
                </OptionList>
              </div>
            </FormElement>
          </div>

          <div>
            <NotificationList display="end-customer-countries" />
            <Countries
              value={locationsOfCustomers}
              onValueChange={setLocationsOfCustomers}
              formLabel="Where are your end-customers?"
            />
          </div>

          <div>
            <FormElement>
              <FormLabel>NAICS Code</FormLabel>
              <FormElementHint>
                Please select your North American Industry Classification System (NAICS) code.
              </FormElementHint>
              <Controller
                name="naicsCode"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <Dropdown
                    onChange={field.onChange}
                    active={field.value}
                    hasError={!!error}
                    search
                    fullWidth
                    maxWidth="520px"
                    options={naicsCodes.map((c) => ({
                      label: `${c.code}: ${c.description}`,
                      value: c.code,
                    }))}
                    variant="muted"
                  />
                )}
              />
            </FormElement>
          </div>

          <div>
            <NotificationList display="business-description" />
            <FormElement>
              <FormLabel>How would you describe your business?</FormLabel>
              <FormElementHint>What products and services do you provide to your customers?</FormElementHint>
              <StyledTextarea
                placeholder="A few sentences on your business"
                onChange={(value) => {
                  setBusinessDescription(value);
                  setBusinessDescriptionError(value.length < 1);
                }}
                value={businessDescription}
                hasError={businessDescriptionError}
              />
            </FormElement>
          </div>
        </RegisterContent>
        <RegisterAction>
          <Button variant="muted" onClick={() => navigate(ROUTE.REGISTER_BUSINESS_LOCATION)} type="button">
            Back
          </Button>
          <Button isLoading={isLoading}>Continue</Button>
        </RegisterAction>
      </Form>
    </RegisterContent>
  );
};
