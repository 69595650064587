import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Button, Icon, PhoneInput, Tooltip } from '@column/column-ui-kit';

import { Notification } from '~/components';
import { useAddress } from '~/hooks';
import { FactorType, FactorTypeResponse } from '~/repositories';
import { useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { FormElement, FormLabel, Grid } from '~/styles';
import { log } from '~/util';
import { reCaptchaCheck } from '~/util/reCaptcha';

const Info = styled.div`
  display: flex;
  gap: 16px;
  align-self: end;
`;

interface Props {
  onPhoneNumberChange: (phoneNumber: string) => void;
}

export const SectionMfaSms: React.FC<Props> = ({ onPhoneNumberChange }) => {
  const openModal = useModalStore((state) => state.openModal);
  const { countries } = useAddress({ types: ['country'] });
  const { currentUser, refetchCurrentUser } = useSessionStore();
  const { addDangerNotification, addInfoNotification } = useNotificationStore();

  const { handleReCaptcha } = reCaptchaCheck();

  const [phoneNumber, setPhoneNumber] = useState<string>('');

  useEffect(() => {
    if (currentUser) {
      setPhoneNumber(currentUser.phoneNumber ?? '');
    }
  }, [currentUser]);

  const handleEnableSmsMfa = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (!currentUser) {
        return;
      }

      if (!phoneNumber) {
        addDangerNotification({
          content: 'Add a valid phone number first.',
          display: 'page',
        });
        return;
      }

      handleReCaptcha(
        (reCaptchaToken) =>
          currentUser
            .update({ phoneNumber }, reCaptchaToken)
            .then(() => {
              handleReCaptcha(
                (reCaptchaTokenMfa) =>
                  currentUser
                    .setupMfa(reCaptchaTokenMfa, {
                      factorName: phoneNumber,
                      factorType: FactorType.SMS,
                    })
                    .then(() => {
                      log({
                        name: 'User enable MFA requested',
                        context: currentUser,
                      });

                      addInfoNotification({
                        content: 'Please confirm your phone number to finish 2FA setup.',
                        display: 'page',
                      });

                      refetchCurrentUser();
                    })
                    .catch((error) => {
                      addDangerNotification({
                        content: error.message,
                        display: 'page',
                      });
                    }),
                (err) => {
                  addDangerNotification({
                    content: err.message,
                    display: 'page',
                  });
                }
              );
            })
            .catch((error) => {
              addDangerNotification({
                content: error.message,
                display: 'page',
              });
            }),
        (err) => {
          addDangerNotification({
            content: err.message,
            display: 'page',
          });
        }
      );
    },
    [currentUser, phoneNumber]
  );

  const handleConfirmMfa = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      openModal('Authenticate');
    },
    [openModal]
  );

  const isMfaPending = useMemo(
    () => currentUser?.factors.some((factor) => factor.factorType === FactorTypeResponse.SMS && !factor.factorVerified),
    [currentUser]
  );

  const isMfaVerified = useMemo(
    () => currentUser?.factors.some((factor) => factor.factorType === FactorTypeResponse.SMS && factor.factorVerified),
    [currentUser]
  );

  if (!currentUser) {
    return null;
  }

  return (
    <Grid>
      <FormElement>
        <FormLabel>Phone Number</FormLabel>
        <PhoneInput
          value={phoneNumber}
          onChange={(value: string) => {
            onPhoneNumberChange(value);
            setPhoneNumber(value);
          }}
          placeholder="Phone number"
          countryList={countries}
        />
      </FormElement>
      <Info>
        {isMfaPending ? (
          <Button onClick={handleConfirmMfa} icon={<Icon.CircleCheck />} variant="muted">
            Confirm
          </Button>
        ) : (
          !isMfaVerified &&
          (currentUser.isEmailVerified ? (
            <Button onClick={handleEnableSmsMfa} icon={<Icon.PhoneAdd />} variant="muted">
              Enable
            </Button>
          ) : (
            <Tooltip content="Verify email first">
              <Button icon={<Icon.PhoneAdd />} variant="muted" isDisabled>
                Enable
              </Button>
            </Tooltip>
          ))
        )}
        {isMfaPending ? (
          <Notification color="info" variant="light" withClose={false}>
            In progress
          </Notification>
        ) : isMfaVerified ? (
          <Notification variant="light" withClose={false}>
            Enabled
          </Notification>
        ) : (
          <Notification color="warning" variant="light" withClose={false}>
            Not enabled
          </Notification>
        )}
      </Info>
    </Grid>
  );
};
