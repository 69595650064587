import { client } from '../client';
import { APIRequest } from '../client/Request';
import { PlatformRolePermissionKey } from '../UserRepository';
import { IconTransferType } from '~/elements';
import { useSessionStore } from '~/stores/Session';
import { TransferServiceListTransfersParams, UnifiedTransfer, UnifiedTransfers } from '~/typings/API';
import { convertValues } from '~/util/convertValues';

export type TransferFilterParams = TransferServiceListTransfersParams;
export type TransferListResponse = UnifiedTransfers;

export type TransferEntry = UnifiedTransfer;
export type TransferTypeID = 'ach' | 'book' | 'issue-check' | 'wire' | 'rdc' | 'check' | 'intl-wire' | 'realtime';
export type TransferEntryType =
  | 'ach_credit'
  | 'ach_debit'
  | 'wire'
  | 'book'
  | 'check_credit'
  | 'check_debit'
  | 'swift'
  | 'realtime';

type TransferAmountHandle = 1 | -1;
type TransferAmountKey = `${TransferEntryType}:${boolean}`;

const TransferAmountMap: Map<TransferAmountKey, TransferAmountHandle> = new Map([
  ['ach_credit:true', 1],
  ['ach_credit:false', -1],
  ['ach_debit:true', -1],
  ['ach_debit:false', 1],
  ['wire:true', 1],
  ['wire:false', -1],
  ['swift:true', 1],
  ['swift:false', -1],
  ['book:true', 1],
  ['book:false', -1],
  ['check_credit:true', 1],
  ['check_credit:false', 1],
  ['check_debit:true', -1],
  ['check_debit:false', -1],
  ['realtime:true', 1],
  ['realtime:false', -1],
]);

export const TransferTypeMap = new Map<TransferEntryType, string>([
  ['ach_debit', 'ACH Debit'],
  ['ach_credit', 'ACH Credit'],
  ['swift', 'Intl. Wire'],
]);

export const convertTransferAmount = (transferType: TransferType, isIncoming: boolean): TransferAmountHandle => {
  return TransferAmountMap.get(`${transferType}:${isIncoming}` as TransferAmountKey) || 1;
};

export interface TransferType {
  id: TransferTypeID;
  idString?: string;
  icon: IconTransferType;
  label: string;
  isInstant?: boolean;
  isComingSoon?: boolean;
  permissionName?: PlatformRolePermissionKey[];
}

export const transfers: TransferType[] = [
  {
    id: 'ach',
    idString: 'acht_',
    icon: 'Ach',
    label: 'ACH Transfer',
    permissionName: ['achCredits', 'achDebits'],
  },
  {
    id: 'book',
    idString: 'book_',
    icon: 'Book',
    label: 'Book Transfer',
    isInstant: true,
    permissionName: ['bookTransfers'],
  },
  {
    id: 'wire',
    idString: 'wire_',
    icon: 'Wire',
    label: 'Wire Transfer',
    isInstant: true,
    permissionName: ['wireTransfers'],
  },
  {
    id: 'issue-check',
    icon: 'IssueCheck',
    label: 'Issue Check',
    permissionName: ['issueChecks'],
  },
  {
    id: 'rdc',
    icon: 'RDC',
    label: 'Remote Deposit Capture',
    permissionName: ['depositChecks'],
  },
  {
    id: 'check',
    idString: 'chkt_',
    icon: 'CheckScanner',
    label: 'Check Scanner',
    permissionName: ['depositChecks'],
  },
  {
    id: 'intl-wire',
    idString: 'swft_',
    icon: 'IntlWire',
    label: 'International Wire',
    isComingSoon: true,
    permissionName: ['wireTransfers'],
  },
  {
    id: 'realtime',
    icon: 'Realtime',
    label: 'Realtime',
    isComingSoon: true,
  },
];

export class TransferRepository {
  static async getAll(query?: Partial<TransferFilterParams>) {
    return client
      .get<Partial<TransferFilterParams>, TransferListResponse>('/transfers', { query })
      .then((response) => ({
        transfers: Array.isArray(response?.transfers)
          ? response.transfers.map((e) => convertValues<UnifiedTransfer>(e))
          : [],
        hasMore: Boolean(response?.hasMore),
        totalResultsCount: response?.totalResultsCount ?? 0,
      }));
  }

  static async export(query?: Partial<TransferFilterParams>) {
    const controller = new AbortController();

    const timeoutId = setTimeout(() => controller.abort(), 1800000);

    const request = new APIRequest<undefined, Partial<TransferFilterParams>>(
      '/transfers/export',
      'GET',
      undefined,
      query,
      {
        'Content-Type': 'application/json',
        'Live-Mode': useSessionStore.getState().isSandbox ? 'No' : 'Yes',
        'Platform-Id': useSessionStore.getState().currentUser?.defaultPlatformId ?? '',
      },
      {
        signal: controller.signal,
      }
    );

    return fetch(request.getRequest())
      .then((response) => {
        clearTimeout(timeoutId);

        return response.text();
      })
      .catch((response) => {
        clearTimeout(timeoutId);

        return response;
      });
  }
}
