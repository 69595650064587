import React, { useState, FormEvent, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Tooltip, Button, Icon, Checkbox, Textarea, Chip, ToggleHeight } from '@column/column-ui-kit';

import { Form } from '../../Login';
import { NotificationList } from '~/components';
import { useNavigate } from '~/lib/navigation';
import { ROUTE } from '~/public/routes';
import { financialProducts, financialProductType } from '~/repositories/PlatformRepository';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Headline, Paragraph, PlatformInfoFormElement, PlatformInfoFormLabelInline } from '~/styles';

export const RegisterContent = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 32px;
`;

export const RegisterContentGrid = styled.div`
  display: grid;
  grid-gap: 12px;
  width: 100%;
`;

const Hint = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
`;

export const OptionList = styled.div`
  text-align: left;
  display: grid;
  grid-gap: 8px;
`;

export const Option = styled.div<{
  isActive?: boolean;
  isDisabled?: boolean;
  isSimple?: boolean;
  withoutScale?: boolean;
}>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  position: relative;
  padding: 12px 24px 12px 42px;
  color: ${({ theme }) => theme.secondary.background};
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(200)};
  transition:
    box-shadow 0.2s,
    background-color 0.2s,
    color 0.2s,
    transform 0.2s;
  opacity: ${({ isDisabled }) => (isDisabled ? '.8' : '1')};

  ${({ withoutScale, isDisabled }) =>
    !withoutScale &&
    !isDisabled &&
    css`
      transform: translateZ(0);

      &:active {
        transform: scaleX(0.985) scaleY(0.975) translateZ(0);
      }
    `}

  & > * {
    z-index: 1;
    position: relative;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    transition: opacity 0.2s;
    opacity: 0;
  }

  &:before {
    background-image: linear-gradient(
      to bottom right,
      ${({ theme }) => theme.primary.blendToBackground(100)},
      ${({ theme }) => theme.background}
    );
  }

  &:after {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.primary.blendToBackground(1000, 250)};
    z-index: 1;
    pointer-events: none;
  }

  ${({ isActive, isDisabled }) =>
    !isActive &&
    !isDisabled &&
    css`
      &:hover {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(400)};
      }
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      &:before,
      &:after {
        opacity: 1;
      }
    `}

  ${({ isSimple }) =>
    isSimple &&
    css`
      padding: 12px;
    `}
`;

export const OptionLabel = styled.span`
  flex-grow: 1;
  display: block;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  z-index: 1;
`;

export const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  left: 14px;
  top: 14px;
  z-index: 1;
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  right: 13px;
  top: 13px;
  z-index: 1;

  svg {
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }
`;

const StyledChip = styled(Chip)`
  box-shadow: none;
  background-color: ${({ theme }) => theme.primary.blendToBackground(1000, 100)};
`;

export const StyledTextarea = styled(Textarea)`
  min-height: 120px;
`;

export const RegisterAction = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin-top: 32px;
`;

export const OtherContainer = styled(ToggleHeight)`
  --toggle-height-padding: 24px 0 0 0;
`;

export const OtherContainerSmall = styled(ToggleHeight)`
  --toggle-height-padding: 12px 0 0 0;
`;

const Examples = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 8px;
  position: relative;
  z-index: 1;
`;

interface FinancialProductsListProps {
  value: financialProductType[];
  onChange: (value: financialProductType[]) => void;
  other: string;
  onChangeOther: (value: string) => void;
}

export const FinancialProductsList: React.FC<FinancialProductsListProps> = (props) => {
  const handleOptionClick = (optionKey: financialProductType) => {
    if (props.value.includes(optionKey)) {
      props.onChange(props.value.filter((k) => k !== optionKey));
      return;
    }
    props.onChange([...props.value, optionKey]);
  };

  return (
    <div>
      <OptionList>
        {Object.keys(financialProducts).map((optionKey) => {
          const key = optionKey as financialProductType;
          const option = financialProducts[key];

          return (
            <Option isActive={props.value.includes(key)} key={optionKey} onClick={() => handleOptionClick(key)}>
              <StyledCheckbox isChecked={props.value.includes(key)} onCheckedChange={() => handleOptionClick(key)} />
              <OptionLabel>{option.label}</OptionLabel>
              {option.examples && (
                <Examples>
                  {option.examples.map((example: string) => (
                    <StyledChip type="info" key={example}>
                      {example}
                    </StyledChip>
                  ))}
                </Examples>
              )}
              {option?.tooltip && (
                <StyledTooltip content={option.tooltip}>
                  <Icon.CircleQuestionmark />
                </StyledTooltip>
              )}
            </Option>
          );
        })}
      </OptionList>

      <OtherContainer isClose={!props.value.includes('other')}>
        <PlatformInfoFormElement onClick={(e) => e.stopPropagation()}>
          <PlatformInfoFormLabelInline>
            Are there other financial product(s) that you would like to offer that do not fit in the
            above&nbsp;categories?
          </PlatformInfoFormLabelInline>
          <StyledTextarea
            placeholder="Details of product"
            onChange={(value: string) => props.onChangeOther(value)}
            value={props.other}
          />
        </PlatformInfoFormElement>
      </OtherContainer>
    </div>
  );
};

export const PageRegisterFinancialProducts: React.FC = () => {
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const currentPlatformState = useSessionStore((state) => state.currentPlatform);
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<financialProductType[]>([]);
  const [text, setText] = useState<string>('');
  const navigate = useNavigate();

  const onSuccess = (event: FormEvent) => {
    event.preventDefault();

    setLoading(true);

    currentPlatformState
      ?.updateMeta({
        financialProductsText: text,
        financialProductsSelected: products,
        financialProductsSubmitted: true,
      })
      .then(() => {
        setLoading(false);
        navigate(ROUTE.REGISTER_COMPANY_EMPLOYEES);
      })
      .catch((error) => {
        setLoading(false);

        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => state.currentPlatform,
        (currentPlatform) => {
          setProducts(currentPlatform?.financialProductsSelected ?? []);
          setText(currentPlatform?.financialProductsText ?? '');
        },
        { fireImmediately: true }
      ),
    []
  );

  return (
    <RegisterContent>
      <RegisterContentGrid>
        <Headline>Which products are you interested in?</Headline>
        <Paragraph size="small">
          The more we know about which products you're interested in, the more helpful we can be.
        </Paragraph>
      </RegisterContentGrid>

      <RegisterContentGrid>
        <Hint>(Select all that apply)</Hint>
        <Form onSubmit={onSuccess}>
          <NotificationList display="page" />
          <FinancialProductsList
            value={products}
            onChange={(value) => setProducts(value)}
            other={text}
            onChangeOther={(value) => setText(value)}
          />
          <RegisterAction>
            <Button variant="muted" onClick={() => navigate(ROUTE.REGISTER)} type="button">
              Back
            </Button>
            <Button isLoading={loading} isDisabled={products.length < 1}>
              Continue
            </Button>
          </RegisterAction>
        </Form>
      </RegisterContentGrid>
    </RegisterContent>
  );
};
