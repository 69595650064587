import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

import { FeatureFlag } from '~/lib/flags/flags';

interface FeatureFlagParams {
  default?: boolean;
}

export const useFeatureFlag = (flag: FeatureFlag, params?: FeatureFlagParams): boolean => {
  const flagSet = useFlags();
  const ldClient = useLDClient();

  return useMemo(() => {
    const flagValue = flagSet[flag];
    if (flagValue === undefined) {
      return params?.default ?? false;
    }

    return flagValue;
  }, [flagSet]);
};
