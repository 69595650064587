import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  Button,
  Icon,
  Dropdown,
  DropdownOption,
  AmountInput,
  formatNumber,
  CurrenciesOrdered,
  CurrenciesUSD,
} from '@column/column-ui-kit';

import { Notification, NotificationList } from '~/components';
import { SimulateRepository } from '~/repositories';
import { BankAccountRepository } from '~/repositories/BankAccountRepository';
import { useModalStore, ModalType } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Headline, Paragraph } from '~/styles';

import { Wrapper, IconCircle, Text } from './Authenticate';
import { Form } from './Counterparty';

const SandboxWrapper = styled(Wrapper)`
  --modal-width: 330px;
`;

const Fields = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const Piggy = styled(Icon.AnimationPiggy)`
  --icon-color: ${({ theme }) => theme.primary.background};
`;

const FormWrapper = styled.div`
  --toggle-height-padding: 0 0 16px 0;
`;

const modalType = 'SandboxTransfer';

export const ModalSandboxTransfer: React.FC = () => {
  const { closeModal, getModalData, getModalIsOpen } = useModalStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const currentUser = useSessionStore((state) => state.currentUser);
  const modalTypes: ModalType[] = [modalType];
  const [accountNumberId, setAccountNumberId] = useState<string>('');
  const [type, setType] = useState<string>('ach-credit');
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [currency, setCurrency] = useState<string>('USD');
  const [bankAccountList, setBankAccountList] = useState<DropdownOption[]>([]);

  const handlePromiseError = (promise: Promise<any>, name: string, callback?: () => void) => {
    return promise
      .then(() => {
        closeModal();

        addSuccessNotification({
          content: `${name} sent`,
          display: 'page',
        });
        if (callback) {
          callback();
        }
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
          display: 'popup',
        });
      });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!accountNumberId) {
      addDangerNotification({
        content: 'Account number id not found',
        display: 'popup',
      });
    }

    if (type === 'ach-credit') {
      handlePromiseError(
        SimulateRepository.receiveACHCredit({
          destinationAccountNumberId: accountNumberId,
          amount: (amount ?? 0).toString(),
          currencyCode: currency ?? '',
        }),
        'ACH credit',
        getModalData()?.callback
      );
      return;
    }

    if (type === 'ach-debit') {
      handlePromiseError(
        SimulateRepository.receiveACHDebit({
          destinationAccountNumberId: accountNumberId,
          amount: (amount ?? 0).toString(),
          currencyCode: currency ?? '',
        }),
        'ACH debit',
        getModalData()?.callback
      );
      return;
    }

    if (type === 'wire') {
      handlePromiseError(
        SimulateRepository.receiveWire({
          destinationAccountNumberId: accountNumberId,
          amount: (amount ?? 0).toString(),
          currencyCode: currency ?? '',
        }),
        'Wire transfer',
        getModalData()?.callback
      );
      return;
    }

    if (type === 'international-wire') {
      handlePromiseError(
        SimulateRepository.receiveInternationalWire({
          destinationAccountNumberId: accountNumberId,
          amount: (amount ?? 0).toString(),
          currencyCode: currency ?? '',
        }),
        'Wire transfer',
        getModalData()?.callback
      );
      return;
    }
  };

  const fetchBankAccounts = async (search?: string) => {
    let result: any = [];

    try {
      const response = await BankAccountRepository.getAll({ description: search });
      result = response.bankAccounts;
    } catch (error) {
      addDangerNotification({
        content: error as string,
        display: 'page',
      });
    }

    const entries: DropdownOption[] = [];
    result.map((entry: any) => {
      entries.push({
        label: `${
          entry?.displayName && entry?.description
            ? `${entry.displayName} – ${entry.description}`
            : entry?.description || 'Unnamed'
        } (${formatNumber(entry?.balances?.availableAmount)})`,
        small: entry.id,
        value: entry.defaultAccountNumberId,
      });
    });

    setBankAccountList(entries);
  };

  const initModal = () => {
    setAccountNumberId(getModalData()?.accountNumberId);

    if (!currentUser) {
      return;
    }
    fetchBankAccounts();
  };

  useEffect(
    () =>
      useModalStore.subscribe(
        (state) => state.openModals,
        (openModals) => {
          if (openModals.includes(modalType)) {
            initModal();
          }
        }
      ),
    []
  );

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => state.currentUser?.defaultPlatformId,
        () => {
          if (getModalIsOpen(modalType)) {
            initModal();
          }
        },
        {
          fireImmediately: true,
        }
      ),
    []
  );

  return (
    <SandboxWrapper modalTypes={modalTypes}>
      <IconCircle>
        <Piggy />
      </IconCircle>
      <Text>
        <Headline fullWidth size="small">
          Sandbox
        </Headline>
        <Paragraph>Simulate ACH/Wire transfers.</Paragraph>
      </Text>
      <NotificationList display="popup" />
      <Form onSubmit={handleSubmit}>
        <FormWrapper>
          <Notification
            variant="light"
            color="info"
            withClose={false}
            isClose={type === 'wire' || type === 'international-wire'}
          >
            Simulated ACH transfers may take 5-10 minutes to show up.
          </Notification>
          <Fields>
            {!getModalData()?.accountNumberId && (
              <Dropdown
                options={bankAccountList}
                active={accountNumberId ?? ''}
                fullWidth
                maxWidth="640px"
                search
                searchLabel="Search for description..."
                onSearchChange={(value: string) => fetchBankAccounts(value)}
                variant="muted"
                onChange={setAccountNumberId}
              />
            )}
            <Dropdown
              onChange={(value: string) => {
                setType(value);
                setCurrency('USD');
              }}
              active={type}
              options={[
                {
                  label: 'Receive ACH credit',
                  value: 'ach-credit',
                },
                {
                  label: 'Receive ACH debit',
                  value: 'ach-debit',
                },
                {
                  label: 'Receive wire',
                  value: 'wire',
                },
                {
                  label: 'Receive an international wire',
                  value: 'international-wire',
                },
              ]}
              fullWidth
              variant="muted"
            />
            <AmountInput
              onChange={setAmount}
              onCurrencyCodeChange={setCurrency}
              currencyCode={currency}
              currencyList={type === 'international-wire' ? CurrenciesOrdered : CurrenciesUSD}
              value={amount}
            />
          </Fields>
        </FormWrapper>
        <Button>Send</Button>
      </Form>
    </SandboxWrapper>
  );
};
