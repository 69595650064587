import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Button, Dropdown, DropdownOption, Icon } from '@column/column-ui-kit';

import { useCounterparties, useDebounce } from '~/hooks';
import { Counterparty, CounterpartyType } from '~/repositories';
import { useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { ApiError } from '~/typings/API';

import { FormField, FormFieldProps } from './FormField';

export interface CounterpartyFieldProps {
  onLoaded?: () => void;
  type: 'wire' | 'ach';
}

interface FormFieldCounterpartyProps<T extends FieldValues> extends FormFieldProps<T>, CounterpartyFieldProps {}

const CounterpartyField = styled.div`
  display: flex;
  gap: 12px;
`;

export const FormFieldCounterparty = <T extends FieldValues>({
  id,
  type,
  onLoaded,
  ...restProps
}: FormFieldCounterpartyProps<T>) => {
  const { createRequest: fetchCounterparties } = useCounterparties({ onInitialLoad: onLoaded });
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const openModal = useModalStore((s) => s.openModal);
  const [counterpartyList, setCounterpartyList] = useState<Counterparty[]>([]);
  const [activeCounterparty, setActiveCounterparty] = useState<CounterpartyType | undefined>(undefined);

  const { setValue } = useFormContext<T>();

  const handleSearchCounterparties = useDebounce(
    async (value?: string) => {
      let list: Counterparty[] = [];

      try {
        if (value && value.length > 0) {
          const isNumber = !isNaN(parseInt(value, 10));
          const results = await Promise.all([
            isNumber ? fetchCounterparties({ accountNumber: value }) : undefined,
            isNumber ? fetchCounterparties({ routingNumber: value }) : undefined,
            fetchCounterparties({ description: value }),
          ]);

          list = [...new Set(results.map((result) => result?.counterparties ?? []).flat())];
        } else {
          const result = await fetchCounterparties();

          list = [...new Set(result?.counterparties ?? [])];
        }
      } catch (error) {
        addDangerNotification({
          display: 'page',
          content: (error as ApiError).message as string,
        });
      }

      setCounterpartyList(list);
    },
    300,
    [fetchCounterparties]
  );

  const handleCounterpartyAdd = useCallback(
    (createdCounterparty: CounterpartyType) => {
      setActiveCounterparty(createdCounterparty);
      setValue(id, createdCounterparty.id as any, {
        shouldValidate: true,
        shouldTouch: true,
      });
    },
    [setValue, id]
  );

  useEffect(() => {
    handleSearchCounterparties();
  }, []);

  const counterpartyToDropdown = useCallback(
    (counterparty: Counterparty): DropdownOption => ({
      label:
        counterparty?.description && counterparty?.name
          ? `${counterparty.name} (${counterparty.description})`
          : counterparty?.description || counterparty?.name || counterparty?.wire?.beneficiaryName || 'Unnamed',
      small: `Rtn# ${counterparty.routingNumber} / Acc# ${counterparty.accountNumber}`,
      value: counterparty.id,
    }),
    []
  );

  return (
    <FormField<T> id={id} updateOn={id} {...restProps}>
      {({ value, onChange }, { error }) => (
        <CounterpartyField>
          <Dropdown
            options={counterpartyList?.map(counterpartyToDropdown) ?? []}
            hiddenOptions={
              activeCounterparty && !counterpartyList.findIndex((c) => c.id !== activeCounterparty?.id)
                ? [counterpartyToDropdown(activeCounterparty)]
                : undefined
            }
            active={value}
            fullWidth
            search
            searchLabel="Search..."
            onSearchChange={handleSearchCounterparties}
            onChange={(c) => {
              onChange(c);
              setActiveCounterparty(c);
            }}
            hasError={!!error}
          />
          <Button
            variant="secondary"
            icon={<Icon.Plus />}
            onClick={() =>
              openModal('Counterparty', {
                type,
                callback: handleCounterpartyAdd,
              })
            }
            type="button"
          >
            New
          </Button>
        </CounterpartyField>
      )}
    </FormField>
  );
};
