import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { PopoverPlatform, PopoverPlatformType } from '~/components';
import { useNavigate } from '~/lib/navigation';
import { UserRepository, userGroups } from '~/repositories';
import { clientWrapper } from '~/repositories/client/wrapper';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { log } from '~/util';
import { reCaptchaCheck } from '~/util/reCaptcha';

const Wrapper = styled.div`
  padding: 0 12px;
  position: relative;
`;

const Action = styled.div`
  position: relative;
  display: flex;
  gap: 0;
  width: 100%;
  align-items: center;
  padding: 8px 10px 8px 17px;
  box-sizing: border-box;
  line-height: 24px;
  cursor: pointer;
  margin-top: -1px;

  &::after {
    content: '';
    position: absolute;
    inset: 1px;
    background-color: ${({ theme }) => theme.secondary.blendToBackground(35)};
    box-shadow:
      ${({ theme }) => theme.style.buttonSecondaryBorderProperties}
        ${({ theme }) => theme.style.buttonSecondaryBorderWidth}
        ${({ theme }) => theme.style.buttonSecondaryBorderColor},
      0 0 0 var(--button-outline, 0px);
    border-radius: 8px;
    z-index: -1;
    transition:
      box-shadow 0.2s,
      background 0.2s;
  }

  &:hover {
    &::after {
      background-color: ${({ theme }) => theme.style.buttonSecondaryHoveredBackgroundColor};
      box-shadow:
        ${({ theme }) => theme.style.buttonSecondaryHoveredBorderProperties}
          ${({ theme }) => theme.style.buttonSecondaryHoveredBorderWidth}
          ${({ theme }) => theme.style.buttonSecondaryHoveredBorderColor},
        0 0 0 var(--button-outline, 0px)
          var(--button-outline-color, ${({ theme }) => theme.style.buttonFocussedOutlineColor}),
        ${({ theme }) => theme.style.buttonSecondaryHoveredShadow};

      svg {
        --icon-color: ${({ theme }) => theme.secondary.background};
      }
    }
  }
`;

const ActionLabel = styled.div`
  display: flex;
  flex: auto;
  align-items: center;
  gap: 8px;
  line-height: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.foreground};
  transition: color 0.2s;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 4px;

  > svg {
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }
`;

const PlatformName = styled.div<{ size?: 'small' | 'large' }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 24px;
  font-size: 14px;
  line-height: 23px;
  vertical-align: middle;
  display: flex;

  ${({ size }) =>
    size === 'small' &&
    css`
      color: ${({ theme }) => theme.secondary.blendToBackground(800)};
      font-size: 12px;
      line-height: 25px;
    `}
`;

const Invites = styled.div`
  width: 16px;
  height: 16px;
  line-height: 16px;
  min-width: 16px;
  text-align: center;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  color: ${({ theme }) => theme.primary.foreground};
  background-color: ${({ theme }) => theme.primary.background};
`;

const Arrow = styled.div`
  & > svg {
    --icon-size: 20px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
    cursor: pointer;

    path {
      transition:
        stroke 0.2s,
        fill 0.2s;
    }
  }
`;

const StyledPopoverPlatform = styled(PopoverPlatform)`
  --popover-left: 13px;
  --popover-top: 44px;
  --popover-min-width: 222px;
`;

export const Platform: React.FC = () => {
  const {
    addEventListener: addSessionEventListener,
    isLoading,
    currentUser,
    currentPlatform,
    triggerEvent: triggerSessionEvent,
  } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const [showPopoverPlatform, setShowPopoverPlatform] = useState<boolean>(false);
  const [platforms, setPlatforms] = useState<PopoverPlatformType[]>([]);
  const [invites, setInvites] = useState<PopoverPlatformType[]>([]);
  const actionRef = useRef<HTMLDivElement>(null);

  const { handleReCaptcha } = reCaptchaCheck();

  const acceptInvite = (id: string) => {
    clientWrapper(
      () => {
        addSuccessNotification({
          content: 'Invite accepted',
          display: 'page',
        });

        setShowPopoverPlatform(false);
        fetchData();
        triggerSessionEvent('accept_platform_invite');
      },
      (e) =>
        addDangerNotification({
          content: e.message,
          display: 'page',
        }),
      UserRepository.acceptInvite({ platformId: id })
    );
  };

  const activeChange = (id: string) => {
    if (!currentUser) {
      return;
    }
    handleReCaptcha(
      (reCaptchaToken) =>
        currentUser
          .update({ defaultPlatformId: id }, reCaptchaToken)
          .then(() => {
            log({
              name: 'Platform changed',
              context: currentUser,
            });
            addSuccessNotification({
              content: 'Platform changed',
              display: 'page',
            });
            setShowPopoverPlatform(false);
            fetchData();
          })
          .catch((error) => {
            addDangerNotification({
              content: error.message,
              display: 'page',
            });
          }),
      (err) => {
        addDangerNotification({
          content: err.message,
          display: 'page',
        });
      }
    );
  };

  const manageClick = () => {
    setShowPopoverPlatform(false);
    navigate(ROUTE.PLATFORMS);
  };

  const fetchData = useCallback(() => {
    if (!currentUser?.id) {
      return;
    }

    clientWrapper(
      (response) => {
        const entries: PopoverPlatformType[] = [];

        if (response.platformRoles.length) {
          response.platformRoles.map((entry) => {
            entries.push({
              id: entry.platformId,
              label: entry.platformName,
              role: userGroups[entry.name],
            });
          });
        }

        setPlatforms(entries);
      },
      (e) => console.error('UserRepository.platformRoles', e),
      UserRepository.platformRoles({ dashboardUserId: currentUser.id })
    );

    clientWrapper(
      (response) => {
        const entries: PopoverPlatformType[] = [];

        if (response.invites.length) {
          response.invites.map((entry) => {
            entries.push({
              id: entry.platformId,
              label: entry.platformName,
              role: userGroups[entry.platformRole],
              isInvite: true,
            });
          });
        }

        setInvites(entries);
      },
      (e) => console.error('UserRepository.getInvites', e),
      UserRepository.getInvites({ dashboardUserId: currentUser?.id })
    );
  }, [currentUser?.id]);

  useEffect(() => {
    fetchData();
  }, [currentUser, showPopoverPlatform, isLoading]);

  useEffect(() => {
    addSessionEventListener('accept_platform_invite', fetchData);
  }, [addSessionEventListener]);

  const handleTriggerClick = useCallback(() => {
    setShowPopoverPlatform((s) => !s);
  }, []);

  const handlePopoverClose = useCallback(
    (event: MouseEvent) => {
      if (!(event.target instanceof Element)) {
        return;
      }
      if (actionRef.current && (event.target === actionRef.current || actionRef.current.contains(event.target))) {
        return;
      }
      setShowPopoverPlatform(false);
    },
    [actionRef]
  );

  const currentPlatformName = currentPlatform?.name;
  return (
    <Wrapper>
      <Action onClick={handleTriggerClick} ref={actionRef}>
        <ActionLabel>
          {currentPlatformName ? (
            <PlatformName size={'large'}>{currentPlatformName}</PlatformName>
          ) : (
            <PlatformName size={'small'}>No Platform Selected</PlatformName>
          )}
          {invites.length > 0 && <Invites>{invites.length}</Invites>}
        </ActionLabel>

        <Arrow>{showPopoverPlatform ? <Icon.ChevronUp /> : <Icon.ChevronDown />}</Arrow>
      </Action>

      <StyledPopoverPlatform
        platforms={[...invites, ...platforms]}
        active={currentUser?.defaultPlatformId}
        show={showPopoverPlatform}
        onActiveChange={activeChange}
        onAcceptClick={acceptInvite}
        onManageClick={manageClick}
        onClose={handlePopoverClose}
      />
    </Wrapper>
  );
};
