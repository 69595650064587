import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, Icon, AmountInput, CurrenciesUSD } from '@column/column-ui-kit';

import { NotificationList } from '~/components';
import { LoanRepository } from '~/repositories';
import { useModalStore, ModalType } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { Headline, Paragraph } from '~/styles';
import { UpdateLoanDisbursementRequest } from '~/typings/API';

import { Wrapper, IconCircle, Text } from './Authenticate';
import { Form } from './Counterparty';

const SandboxWrapper = styled(Wrapper)`
  --modal-width: 330px;
`;

const Fields = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const Clock = styled(Icon.AnimationClock)`
  --icon-color: ${({ theme }) => theme.primary.background};
`;

export const ModalUpdateDisbursement: React.FC = () => {
  const { closeModal, getModalData } = useModalStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const modalTypes: ModalType[] = ['UpdateDisbursement'];
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [currency, setCurrency] = useState<string>('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!getModalData()?.disbursementId) {
      return;
    }

    let data: any = {};
    if (amount && amount > 0) {
      data = { ...data, amount, currency };
    }

    if (data.amount) {
      data.amount = data.amount.toString();
    }

    LoanRepository.clearDisbursement(getModalData()?.disbursementId, data as UpdateLoanDisbursementRequest)
      .then(() => {
        closeModal();
        addSuccessNotification({
          content: 'Disbursement updated',
          display: 'page',
        });
        if (getModalData()?.callback) {
          getModalData()?.callback();
        }
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
          display: 'popup',
        });
      });
  };

  return (
    <SandboxWrapper modalTypes={modalTypes}>
      <IconCircle>
        <Clock />
      </IconCircle>
      <Text>
        <Headline fullWidth size="small">
          Update disbursement
        </Headline>
        <Paragraph>Only the amount can be updated.</Paragraph>
      </Text>
      <NotificationList display="popup" />
      <Form onSubmit={handleSubmit}>
        <Fields>
          <AmountInput
            onChange={setAmount}
            onCurrencyCodeChange={setCurrency}
            currencyCode={currency}
            currencyList={CurrenciesUSD}
            value={amount}
          />
        </Fields>
        <Button>Update</Button>
      </Form>
    </SandboxWrapper>
  );
};
