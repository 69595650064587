import React, { useEffect, memo, useMemo, FC, ElementType } from 'react';
import ReactGA from 'react-ga4';
import { Route, useLocation, useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { Page, RouteContent, Layout, CustomPage } from '~/app/Layout';
import { PAGE } from '~/app/pages';
import { ROUTE } from '~/app/routes';
import { Sitemap, PageType, PageTypeLevel, PageTypeKey } from '~/app/Sitemap';
import { RestrictedState } from '~/components';
import { EntityRepository, PlatformRolePermissions } from '~/repositories';
import { clientWrapper } from '~/repositories/client/wrapper';
import { useSessionStore } from '~/stores/Session';
import { RootRoutes, checkIfRestricted } from '~/util';

interface RenderProps {
  page: PageType<PageTypeLevel>;
  currentPermission?: PlatformRolePermissions;
  url: string;
}

const isComponent = (Component: any): boolean => {
  return Component && typeof Component === 'function';
};

const createRoute = ({ page, currentPermission, url }: RenderProps) => {
  const nestedPath = typeof page.url === 'string' ? page.url : page.url.route;
  const path = `${url}/${nestedPath}`.replace(/\/+/g, '/');

  if ('childPages' in page) {
    let element;

    if (page.routeElement === 'Page') {
      element = (
        <Page
          headline={page.title}
          entries={page.childPages
            .filter((childPage) => childPage.visibility === 'tab')
            .map((childPage) => {
              const childPath =
                typeof childPage.url === 'string' ? childPage.url : childPage.url.redirect || childPage.url.route;

              return {
                label: childPage?.label || childPage.title,
                path: childPath === '' ? path : `${path}/${childPath}`,
                removeWildcard: childPath === '',
              };
            })}
        />
      );
    }

    if (isComponent(page.routeElement)) {
      const Component = page.routeElement;

      element = (
        <CustomPage
          Component={Component as ElementType}
          entries={page.childPages
            .filter((childPage) => childPage.visibility === 'tab')
            .map((childPage) => {
              const childPath =
                typeof childPage.url === 'string' ? childPage.url : childPage.url.redirect || childPage.url.route;

              return {
                label: childPage?.label || childPage.title,
                path: childPath === '' ? path : `${path}/${childPath}`,
                removeWildcard: childPath === '',
              };
            })}
        />
      );
    }

    return (
      <Route key={path} path={path} element={element}>
        {page.childPages.map((childPage) => createRoute({ page: childPage, currentPermission, url: path }))}

        <Route index element={<PageRenderer page={page} />} />
      </Route>
    );
  }

  if (page.routeElement === 'ContentSmall' || page.routeElement === 'ContentDefault') {
    return (
      <Route
        key={path}
        path={path}
        element={<RouteContent container={page.routeElement === 'ContentSmall' ? 'small' : undefined} />}
      >
        <Route index element={<PageRenderer page={page} />} />
      </Route>
    );
  }

  if (page.routeElement === 'ContentFullWidth') {
    return (
      <Route key={path} path={path} element={<RouteContent container="fullWidth" />}>
        <Route index element={<PageRenderer page={page} />} />
      </Route>
    );
  }

  return <Route key={path} path={path} element={<PageRenderer page={page} />} />;
};

const PageRenderer: FC<Pick<RenderProps, 'page'>> = ({ page }) => {
  const currentPermission = useSessionStore((state) => state.currentPermission);

  if (currentPermission && page.restrictIf && checkIfRestricted(currentPermission, page.restrictIf)) {
    return <RestrictedState />;
  }

  if (page.content in PAGE) {
    const Element = PAGE[page.content as PageTypeKey];
    return <Element />;
  }

  if (page.content === 'redirectToFirstChild') {
    return <PageRenderer page={page.childPages[0]} />;
  }

  return page.content;
};

export const Router = memo(() => {
  const { isSandbox, currentPlatform, currentUser, setRootEntity, setRootEntitySandbox, currentPermission } =
    useSessionStore();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          currentPermission: state.currentPermission,
          currentPlatform: state.currentPlatform,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        (state) => {
          if (!state.defaultPlatformId || !state.currentPermission) {
            return;
          }

          if (state.currentPermission.platformInfo === 'none') {
            setRootEntity(undefined);
            return;
          }

          if (state.currentPlatform?.isLiveEnabled)
            clientWrapper(
              setRootEntity,
              () => setRootEntity(undefined),
              EntityRepository.getRoot(state.defaultPlatformId, 'live')
            );

          clientWrapper(
            setRootEntitySandbox,
            () => setRootEntitySandbox(undefined),
            EntityRepository.getRoot(state.defaultPlatformId, 'sandbox')
          );
        },
        {
          fireImmediately: true,
          equalityFn: shallow,
        }
      ),
    []
  );

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search + location.hash });
  }, [location]);

  const RenderSitemap = useMemo(() => {
    const isPlatformActive = currentPlatform !== undefined;
    return Sitemap(isPlatformActive, isSandbox).map((page) => {
      return createRoute({ page, currentPermission, url: '/' });
    });
  }, [isSandbox, currentPermission]);

  useEffect(() => {
    if (!currentPlatform && location.pathname === ROUTE.ROOT) {
      navigate(ROUTE.PROFILE);
    }
  }, [currentPlatform, location.pathname]);

  if (!currentUser) {
    return null;
  }

  return (
    <Layout>
      <RootRoutes>{RenderSitemap}</RootRoutes>
    </Layout>
  );
});

export default Router;
