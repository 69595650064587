import { datadogLogs } from '@datadog/browser-logs';
import React, { useEffect, memo, useCallback } from 'react';
import ReactGA from 'react-ga4';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ROUTE as ROUTE_APP } from '~/app/routes';
import { MODE, DATATDOG_BROWSER_LOGS_KEY, IS_PRODUCTION, HOST } from '~/constants';
import { Router as PublicRouter } from '~/public/Router';
import { ThemeProvider } from '~/Theme/ThemeProvider';

import { App } from './app';

ReactGA.initialize('G-SSHXC1SVP5');

export const Router = memo(() => {
  const handleErrors = useCallback(({ error, message, filename, lineno, colno }: ErrorEvent) => {
    datadogLogs.logger.error('Unhandled Exception', {
      error,
      message,
      filename,
      lineno,
      colno,
    });
  }, []);

  const handleUnhandledRejection = useCallback(({ reason }: PromiseRejectionEvent) => {
    datadogLogs.logger.error('Unhandled Promise Rejection', {
      reason,
    });
  }, []);

  useEffect(() => {
    if (!IS_PRODUCTION || window.location.hostname !== HOST) {
      return;
    }

    datadogLogs.init({
      clientToken: DATATDOG_BROWSER_LOGS_KEY,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
      sessionSampleRate: 100,
      version: import.meta.env.VITE_APP_VERSION || 'development',
      env: MODE === 'production' ? 'prod' : MODE,
      service: 'dashboard',
      beforeSend: (log) => {
        if (log.http && log.http.status_code !== 500) {
          return false;
        }

        return true;
      },
    });

    window.addEventListener('error', handleErrors);
    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.removeEventListener('error', handleErrors);
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, []);

  return (
    <ThemeProvider>
      <GoogleReCaptchaProvider reCaptchaKey="6Leh8AQfAAAAAEPSCKOFfX053-T22KKBqtd5YB0U">
        <BrowserRouter>
          <Routes>
            <Route path={`${ROUTE_APP.ROOT}/*`} element={<App />} />
            <Route path="*" element={<PublicRouter />} />
          </Routes>
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  );
});
