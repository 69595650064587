import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon, Input } from '@column/column-ui-kit';

const KEY_CODE = {
  backspace: 8,
  left: 37,
  right: 39,
};

export interface CodeInputStyleProps {
  isDisabled: boolean;
  isLoading: boolean;
}

export interface CodeInputProps extends Partial<CodeInputStyleProps> {
  onChange?: (value: string) => void;
  fields?: number;
  className?: string;
  type?: 'text' | 'password' | 'number';
  value?: string;
  placeholder?: string;
  autoFocus?: boolean;
}

const CodeInputWrapper = styled.div`
  max-width: var(--code-input-width, 244px);
  position: relative;
`;

const FieldsWrapper = styled.div<CodeInputStyleProps>`
  display: flex;
  gap: 8px;
  transition: filter 0.2s;

  ${({ isLoading }) =>
    isLoading &&
    css`
      filter: blur(3px);
    `}
`;

const Loading = styled(Icon.Loading)<CodeInputStyleProps>`
  --icon-color: currentColor;

  position: absolute;
  left: 50%;
  top: 50%;
  margin: -10px 0 0 -10px;
  transition:
    transform 0.2s,
    opacity 0.2s;

  ${({ isLoading }) =>
    !isLoading &&
    css`
      opacity: 0;
      pointer-events: none;
      transform: translateY(-12px) scale(0.75);
    `}
`;

const StyledInput = styled(Input)`
  min-width: 34px;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 16px;
  text-align: center;
  box-sizing: border-box;
`;

export const CodeInput: React.FC<CodeInputProps> = (props) => {
  const fieldNum = props.fields ?? 6;
  const [focussed, setFocussed] = useState<number>(-1);
  const [values, setValues] = useState<string[]>(new Array(fieldNum));

  const styleProps: CodeInputStyleProps = {
    isDisabled: props.isDisabled ?? false,
    isLoading: props.isLoading ?? false,
  };

  const triggerChange = (value: string) => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  const handleChange = (value: string, index: number) => {
    const newValues = [...values];
    let next;

    if (value.length > 1) {
      let nextIndex = value.length + index - 1;
      if (nextIndex >= fieldNum) {
        nextIndex = fieldNum - 1;
      }
      next = nextIndex;
      const split = value.split('');
      split.forEach((item, i) => {
        const cursor = index + i;
        if (cursor < fieldNum) {
          newValues[cursor] = item;
        }
      });
      setValues(newValues);
    } else {
      next = index + 1;
      newValues[index] = value;
      setValues(newValues);
    }

    triggerChange(newValues.join(''));

    if (next >= fieldNum) {
      next = null;
    }

    if (next) {
      setFocussed(next);
    }
  };

  const keyDown = (e: any, index: number) => {
    const prevIndex = index - 1;
    const prev = focussed - 1;
    const next = focussed + 1;

    switch (e.keyCode) {
      case KEY_CODE.backspace:
        e.preventDefault();
        const newValues = [...values];
        if (values[index]) {
          newValues[index] = '';
          setValues(newValues);
        } else if (prev >= 0) {
          newValues[prevIndex] = '';
          setValues(newValues);
          setFocussed(prev);
        }
        triggerChange(newValues.join(''));
        break;
      case KEY_CODE.left:
        e.preventDefault();
        setFocussed(prev >= 0 ? prev : fieldNum - 1);
        break;
      case KEY_CODE.right:
        e.preventDefault();
        setFocussed(next < fieldNum ? next : 0);
        break;
    }
  };

  useEffect(() => {
    const newValues = new Array(fieldNum).fill(undefined);
    for (let i = 0; i < fieldNum; i++) {
      if (props.value && props.value.split('')[i]) {
        newValues[i] = props.value.split('')[i];
      }
    }
    setValues(newValues);
  }, [props.value, fieldNum]);

  useEffect(() => {
    if (props.autoFocus && props.value === '') {
      setFocussed(0);
    }
  }, [props.autoFocus, props.value]);

  return (
    <CodeInputWrapper className={props.className}>
      <FieldsWrapper {...styleProps}>
        {values.map((value, index) => (
          <StyledInput
            value={value || ''}
            onChange={(val: string) => handleChange(val, index)}
            onKeyDown={(e: any) => keyDown(e, index)}
            placeholder={(props.placeholder && props.placeholder[index]) || undefined}
            key={index}
            isFocussed={index === focussed}
            onFocus={() => setFocussed(index)}
            onBlur={() => setFocussed(-1)}
            type={props.type === 'number' ? 'tel' : props.type}
            pattern={props.type === 'number' ? '[0-9]*' : undefined}
            isDisabled={styleProps.isDisabled}
          />
        ))}
      </FieldsWrapper>
      <Loading {...styleProps} />
    </CodeInputWrapper>
  );
};
