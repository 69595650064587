import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Button, Icon } from '@column/column-ui-kit';

import { SetupMfaTotpModal } from '~/app/pages/User/Personal/SetupTotpModal';
import { QRCodeIcon } from '~/elements';
import { FactorTypeResponse, UserRepository } from '~/repositories';
import { useAlertStore } from '~/stores/Alert';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Box, FormElement, FormLabel, Grid, Inner } from '~/styles';

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MfaMessage = styled.div`
  display: flex;
  gap: 8px;
  flex: auto;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: ${({ theme }) => theme.secondary.background};
`;

const StyledQRCodeIcon = styled(QRCodeIcon)`
  --icon-size: 24px;
  --icon-color: ${({ theme }) => theme.secondary.background};
`;

const Success = styled(Icon.Checkmark)`
  --icon-color: ${({ theme }) => theme.success.background};
  --icon-size: 24px;
`;

enum TotpAction {
  Disable = 'Disable',
  Enable = 'Enable',
}

export const SectionMfaTotp = () => {
  const { openAlert } = useAlertStore();
  const { currentUser, refetchCurrentUser } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();

  const [setupMfaTotpModalOpen, setSetupMfaTotpModalOpen] = useState<boolean>(false);
  const closeSetupMfaTotpModal = useCallback(() => setSetupMfaTotpModalOpen(false), []);

  const totpFactor = useMemo(
    () => currentUser?.factors.find((factor) => factor.factorType === FactorTypeResponse.TOTP && factor.factorVerified),
    [currentUser]
  );
  const totpAction = useMemo(() => (totpFactor ? TotpAction.Disable : TotpAction.Enable), [totpFactor]);

  const handleDisableTotpMfa = useCallback(() => {
    const secondaryText = currentUser?.factors.find((factor) => factor.factorType === FactorTypeResponse.SMS)
      ? 'If authenticator app two-factor authentication is disabled, your phone number will be used instead.'
      : 'If two-factor authentication is disabled, your account will be less secure.';

    openAlert({
      headline: 'Disable Authenticator App',
      text: `This action cannot be undone. ${secondaryText}`,
      submitText: 'Disable',
      size: 'small',
      callback: () => {
        if (!totpFactor) {
          return;
        }
        UserRepository.deleteFactor({ factorId: totpFactor.factorId })
          .then(() => {
            addSuccessNotification({
              content: 'Authenticator app disabled',
              display: 'page',
            });
            refetchCurrentUser();
          })
          .catch((error) => {
            addDangerNotification({
              content: error.message,
              display: 'page',
            });
          });
      },
    });
  }, [currentUser, totpFactor]);

  return (
    <Grid vertical>
      <SetupMfaTotpModal
        onClose={closeSetupMfaTotpModal}
        onComplete={() => {
          closeSetupMfaTotpModal();
          refetchCurrentUser();
        }}
        onError={closeSetupMfaTotpModal}
        open={setupMfaTotpModalOpen}
      />
      <Grid>
        <FormElement>
          <FormLabel>Authenticator App</FormLabel>

          <Box variant="secondary">
            <Inner py={12} px={16}>
              <Flex>
                <MfaMessage>
                  {totpAction === TotpAction.Disable ? <Success /> : <StyledQRCodeIcon />}

                  <span>
                    {!!totpFactor
                      ? 'Your authenticator app is enabled.'
                      : 'Generate one-time verification codes with your preferred authenticator app.'}
                  </span>
                </MfaMessage>

                <Button
                  size="small"
                  variant={totpAction === TotpAction.Disable ? 'muted' : 'secondary'}
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    if (totpAction === TotpAction.Disable) {
                      handleDisableTotpMfa();
                    } else {
                      setSetupMfaTotpModalOpen(true);
                    }
                  }}
                >
                  {totpAction}
                </Button>
              </Flex>
            </Inner>
          </Box>
        </FormElement>
      </Grid>
    </Grid>
  );
};
