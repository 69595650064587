import { client } from '../client';
import {
  InternationalWireTransfer,
  CreateInternationalWireTransferRequest,
  InternationalWireTransferList,
  FxQuote,
  FxRateSheet,
  WireTransferServiceListWireTransfersParams,
  ReturnInternationalWireTransferRequest,
  CancelInternationalWireTransferRequest,
  InternationalWireTransferTracking,
} from '~/typings/API';
import { convertValues } from '~/util';

export type IntlWireTransfer = InternationalWireTransfer;
export type IntlWireTransferList = InternationalWireTransferList;
export type IntlWireTransferListParams = Pick<
  WireTransferServiceListWireTransfersParams,
  'bankAccountId' | 'limit' | 'created.gt' | 'created.gte' | 'created.lt' | 'created.lte'
>;
export type FxRateType = FxRateSheet['data'][number];
export type CreateIntlWireTransfer = CreateInternationalWireTransferRequest;

export type { FxQuote, FxRateSheet };

interface GetIntlWireQuery {
  expand?: string;
}

interface ReturnIncomingIntlWireRequest extends ReturnInternationalWireTransferRequest {
  id: string;
}

interface CancelOutgoingIntlWireRequest extends CancelInternationalWireTransferRequest {
  id: string;
}

export interface CreateFXQuoteRequest {
  buyAmount: number;
  buyCurrencyCode: string;
  rateDate?: string;
  bookDirectly?: boolean;
}

interface GetIntlWireTrackingRequest {
  swiftTransferIdOrUetr: string;
}

const intlWireTransferCast = ({ beneficiaryAccountNumber, originatorAccountNumber, fxRate }: IntlWireTransfer) => ({
  beneficiaryAccountNumber,
  originatorAccountNumber,
  fxRate,
});

export class IntlWireRepository {
  static async get(request: GetRequestType, query?: GetIntlWireQuery) {
    return client
      .get<unknown, IntlWireTransfer>(`/transfers/international-wire/${request.id}`, { query })
      .then((response) => convertValues<IntlWireTransfer>(response, undefined, intlWireTransferCast(response)));
  }

  static async create(wireTransfer: CreateInternationalWireTransferRequest, idempotency?: string) {
    return client
      .post<CreateInternationalWireTransferRequest, IntlWireTransfer>('/transfers/international-wire', wireTransfer, {
        idempotency,
      })
      .then((response) => convertValues<IntlWireTransfer>(response, undefined, intlWireTransferCast(response)));
  }

  static async getAll(query?: IntlWireTransferListParams) {
    return client
      .get<IntlWireTransferListParams, IntlWireTransferList>('/transfers/international-wire', { query })
      .then((response) => ({
        int: Array.isArray(response?.transfers)
          ? response.transfers.map((e) => convertValues<IntlWireTransfer>(e, undefined, intlWireTransferCast(e)))
          : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }

  static async getFxRateSheet() {
    return client.get<unknown, FxRateSheet>('/transfers/international-wire/fx-rate-sheet').then((response) => ({
      data: Array.isArray(response?.data) ? response.data.map((e) => convertValues<FxRateType>(e)) : [],
    }));
  }

  static async getFxQuote(request: GetRequestType) {
    return client
      .get<unknown, FxQuote>(`/transfers/international-wire/fx-rate/${request.id}`)
      .then((response) => convertValues<FxQuote>(response));
  }

  static async createFxQuote(request: CreateFXQuoteRequest, idempotency?: string) {
    return client
      .post<CreateFXQuoteRequest, FxQuote>('/transfers/international-wire/fx-rate', request, { idempotency })
      .then((response) => convertValues<FxQuote>(response));
  }

  static async bookFxQuote(request: GetRequestType) {
    return client
      .post<undefined, FxQuote>(`/transfers/international-wire/fx-rate/${request.id}/book`, undefined)
      .then((response) => convertValues<FxQuote>(response));
  }

  static async cancelFxQuote(request: GetRequestType) {
    return client
      .post<object, FxQuote>(`/transfers/international-wire/fx-rate/${request.id}/cancel`, {})
      .then((response) => convertValues<FxQuote>(response));
  }

  static async returnIncoming(data: ReturnIncomingIntlWireRequest) {
    const { id, ...request } = data;

    return client
      .post<
        Omit<ReturnIncomingIntlWireRequest, 'id'>,
        IntlWireTransfer
      >(`/transfers/international-wire/${id}/return`, request)
      .then((response) => convertValues<IntlWireTransfer>(response, undefined, intlWireTransferCast(response)));
  }

  static async cancelOutgoing(data: CancelOutgoingIntlWireRequest) {
    const { id, ...request } = data;

    return client
      .post<
        Omit<CancelOutgoingIntlWireRequest, 'id'>,
        IntlWireTransfer
      >(`/transfers/international-wire/${id}/cancel`, request)
      .then((response) => convertValues<IntlWireTransfer>(response, undefined, intlWireTransferCast(response)));
  }

  static async getTracking(request: GetIntlWireTrackingRequest) {
    return client
      .get<
        unknown,
        InternationalWireTransferTracking
      >(`/transfers/international-wire/${request.swiftTransferIdOrUetr}/tracking`)
      .then((response) => convertValues<InternationalWireTransferTracking>(response));
  }
}
