import React, { FormEvent, useCallback, useState } from 'react';

import { Button } from '@column/column-ui-kit';

import { Form } from '../../Login';
import {
  Option,
  OptionLabel,
  OptionList,
  RegisterAction,
  RegisterContent,
  StyledCheckbox,
  RegisterContentGrid,
} from '../Questions';
import { ROUTE as APP_ROUTE } from '~/app/routes';
import { PDF_URL } from '~/assets';
import { NotificationList } from '~/components';
import { HandButton, UnderlineLink } from '~/elements';
import { useNavigate } from '~/lib/navigation';
import { ROUTE } from '~/public/routes';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Headline, Paragraph } from '~/styles';

export const PageRegisterBusinessSubmit: React.FC = () => {
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const currentPlatformState = useSessionStore((state) => state.currentPlatform);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [informationConfirmed, setInformationConfirmed] = useState<boolean>(false);
  const [prohibitedCategroriesListAgreed, setProhibitedCategroriesListAgreed] = useState<boolean>(false);
  const [ddaAgreement, setDdaAgreement] = useState<boolean>(false);

  const handleInformationConfirmed = useCallback((value?: boolean) => {
    if (typeof value === 'boolean') {
      setInformationConfirmed(value);
      return;
    }
    setInformationConfirmed((s) => !s);
  }, []);

  const handleProhibitedCategroriesListAgreed = useCallback((value?: boolean) => {
    if (typeof value === 'boolean') {
      setProhibitedCategroriesListAgreed(value);
      return;
    }
    setProhibitedCategroriesListAgreed((s) => !s);
  }, []);

  const handleDdaAgreement = useCallback((value?: boolean) => {
    if (typeof value === 'boolean') {
      setDdaAgreement(value);
      return;
    }
    setDdaAgreement((s) => !s);
  }, []);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    if (!informationConfirmed || !prohibitedCategroriesListAgreed || !ddaAgreement) {
      addDangerNotification({
        content: 'Please agree to & check all entries',
        display: 'page',
      });

      return;
    }

    setIsLoading(true);

    currentPlatformState
      ?.updateDepgraphNodeValues({
        submitted: true,
      })
      .then(() => {
        if (currentPlatformState) {
          currentPlatformState.platformLevel = 'kyb_pending';
        }
        navigate(APP_ROUTE.ROOT, {
          state: {
            from: 'register',
          },
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <RegisterContent>
      <RegisterContentGrid>
        <Headline>Submit</Headline>
        <Paragraph size="small">
          Submit your information for KYC. We will be in contact with updates to your approval process.
        </Paragraph>
      </RegisterContentGrid>
      <Form onSubmit={handleSubmit}>
        <NotificationList display="page" />
        <OptionList>
          <Option isActive={informationConfirmed} onClick={() => handleInformationConfirmed()}>
            <StyledCheckbox isChecked={informationConfirmed} onCheckedChange={handleInformationConfirmed} />
            <OptionLabel>
              I certify to the best of my knowledge that all the information I’ve provided, including beneficial
              ownership information, is accurate.
            </OptionLabel>
          </Option>
          <Option isActive={prohibitedCategroriesListAgreed} onClick={() => handleProhibitedCategroriesListAgreed()}>
            <StyledCheckbox
              isChecked={prohibitedCategroriesListAgreed}
              onCheckedChange={handleProhibitedCategroriesListAgreed}
            />
            <OptionLabel>
              I agree to the{' '}
              <UnderlineLink
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  window.open(PDF_URL.ColumnProhibitedCategories, '_blank');
                }}
              >
                Column Prohibited Categories List
              </UnderlineLink>
            </OptionLabel>
          </Option>
          <Option isActive={ddaAgreement} onClick={() => handleDdaAgreement()}>
            <StyledCheckbox isChecked={ddaAgreement} onCheckedChange={handleDdaAgreement} />
            <OptionLabel>
              I agree to the{' '}
              <UnderlineLink
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  window.open('https://column.com/legal/commercial-deposit-account-agreement', '_blank');
                }}
              >
                Column Commercial Deposit Account&nbsp;Agreement
              </UnderlineLink>
            </OptionLabel>
          </Option>
        </OptionList>
        <RegisterAction>
          <Button variant="muted" onClick={() => navigate(ROUTE.REGISTER_BUSINESS_YOUR_INFORMATION)} type="button">
            Back
          </Button>
          <HandButton isLoading={isLoading}>Submit</HandButton>
        </RegisterAction>
      </Form>
    </RegisterContent>
  );
};
