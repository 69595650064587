import React, { FC, PropsWithChildren, useState, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { Icon, ToggleHeight } from '@column/column-ui-kit';

import { Headline } from '~/styles';

type SectionVariantType = 'isBoxed' | 'isCompact';

export interface SectionProps {
  headline?: string;
  description?: string;
  isCollapsed?: boolean;
  variant?: SectionVariantType | SectionVariantType[];
}

interface SectionStyleProps {
  $isOpen?: boolean;
  $isBoxed?: boolean;
  $isCompact?: boolean;
}

const Wrapper = styled.div<SectionStyleProps>`
  padding: 24px;
  display: grid;
  grid-gap: ${({ $isOpen, $isCompact }) =>
    $isCompact ? '0' : $isOpen || typeof $isOpen === 'undefined' ? '24px' : '0'};
  box-sizing: border-box;
  grid-template-columns: 100%;
  align-items: start;
  justify-content: start;
  box-shadow: 0 -1px 0 ${({ theme }) => theme.secondary.blendToBackground(200)};

  &:first-of-type {
    box-shadow: none;
  }

  ${({ $isCompact }) =>
    $isCompact &&
    css`
      padding: 0;

      &:not(:first-of-type) {
        box-shadow: none;
      }
    `}

  ${({ $isBoxed }) =>
    $isBoxed &&
    css`
      background-color: transparent;
      border-radius: 8px;
      margin: 0 24px 24px;
      padding: 0;
      grid-gap: 0;
      box-shadow: none;
      overflow: hidden;
      position: relative;
      box-shadow:
        0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(200)},
        0 0 0 -1px ${({ theme }) => theme.secondary.blendToBackground(200)};
      transition:
        background-color 0.2s,
        grid-gap 0.2s;

      &:not(:last-of-type) {
        box-shadow:
          0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(200)},
          0 0 0 -1px ${({ theme }) => theme.secondary.blendToBackground(200)};
      }

      background-color: ${({ theme }) => theme.secondary.blendToBackground(35)};
      box-shadow:
        0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(100)},
        0 0 0 -1px ${({ theme }) => theme.secondary.blendToBackground(100)};

      &:not(:last-of-type) {
        box-shadow:
          0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(100)},
          0 0 0 -1px ${({ theme }) => theme.secondary.blendToBackground(100)};
      }

      ${Header} {
        margin: 0;
      }

      > .toggleHeight {
        --toggle-height-padding: 0 24px 24px;

        > div:first-child {
          margin-top: 8px;
        }

        .section {
          padding: 24px;
          margin: 0 -24px;
          box-shadow: 0 -1px 0 ${({ theme }) => theme.secondary.blendToBackground(200)};
        }
      }

      .form-field {
        grid-template-columns: calc(25% - 12px) auto calc(25% - 12px);
      }
    `}
`;

const Header = styled.div<SectionStyleProps>`
  display: flex;
  align-items: start;
  gap: 4px;
  transition: background-color 0.2s;

  ${({ $isBoxed }) =>
    $isBoxed &&
    css`
      padding: 16px 24px;
      border-radius: 8px;
    `}

  ${({ $isOpen }) =>
    typeof $isOpen !== 'undefined' &&
    css`
      cursor: pointer;

      svg {
        margin-left: -4px;
      }
    `}

  ${({ $isOpen, $isBoxed }) =>
    ($isOpen === false || typeof $isOpen === 'undefined') &&
    $isBoxed &&
    css`
      background-color: ${({ theme }) => theme.background};

      &:hover {
        background-color: ${({ theme }) => theme.secondary.blendToBackground(50)};
      }
    `};

  ${({ $isOpen }) =>
    $isOpen === true &&
    css`
      &:hover {
        background-color: transparent;
      }
    `};
`;

const Info = styled.div`
  display: grid;
  grid-gap: 4px;
  justify-content: start;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  font-size: 12px;
  line-height: 18px;
`;

const Content = styled.div`
  display: grid;
  gap: 24px;
`;

export const Section: FC<PropsWithChildren<SectionProps>> = ({
  headline,
  description,
  children,
  variant,
  isCollapsed,
}) => {
  const [isOpenState, setIsOpenState] = useState<boolean | undefined>(
    typeof isCollapsed === 'boolean' ? !isCollapsed : undefined
  );

  const handleOpenToggle = useCallback(() => {
    if (typeof isCollapsed === 'undefined') {
      return;
    }

    setIsOpenState((s) => !s);
  }, [isCollapsed]);

  const variants = useMemo(() => (typeof variant === 'string' ? [variant] : variant), [variant]);
  const checkClass = useCallback((style: SectionVariantType) => variants?.includes(style) ?? false, [variants]);

  return (
    <Wrapper
      className="section"
      $isBoxed={checkClass('isBoxed')}
      $isCompact={checkClass('isCompact')}
      $isOpen={isOpenState}
    >
      {headline && (
        <Header $isOpen={isOpenState} onClick={handleOpenToggle} $isBoxed={checkClass('isBoxed')}>
          {typeof isOpenState !== 'undefined' ? isOpenState ? <Icon.ChevronUp /> : <Icon.ChevronDown /> : null}

          <Info>
            <Headline size="small">{headline}</Headline>

            {String(description).length > 0 && <Description>{description}</Description>}
          </Info>
        </Header>
      )}

      <ToggleHeight isClose={typeof isOpenState !== 'undefined' && !isOpenState} className="toggleHeight">
        <Content>{children}</Content>
      </ToggleHeight>
    </Wrapper>
  );
};
