import { gsap } from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import React, { useRef, useCallback, useContext, useEffect, useState, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { ROUTE } from '~/app/routes';
import { CodeInput, NotificationList } from '~/components';
import { useNavigate } from '~/lib/navigation';
import { ROUTE as PUBLIC } from '~/public/routes';
import { ChallengeName, UserRepository } from '~/repositories/UserRepository';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Headline, Hint, Paragraph } from '~/styles';
import { log } from '~/util';
import { reCaptchaCheck } from '~/util/reCaptcha';

import { Form, Rows, HiddenLogo, AdditionalLogo, StyledPanel, LogoWrapper, animateLogo } from './Login';

interface PageAuthProps {
  isAuthFlow?: boolean;
}

const Text = styled.div`
  display: grid;
  grid-gap: 8px;
`;

const Resend = styled.span`
  display: inline-block;
  cursor: pointer;
  color: ${({ theme }) => theme.primary.background};
`;

const StyledNotificationList = styled(NotificationList)`
  --toggle-height-padding: 0 0 16px 0;
`;

export const PageAuth: React.FC<PageAuthProps> = ({ isAuthFlow }) => {
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const {
    authChallenge,
    isSandbox,
    phoneNumber,
    signIn,
    setAuthChallenge,
    setPhoneNumber,
    params,
    getRedirectUrlOrRedirect,
  } = useSessionStore();
  const logoWrapperRef = useRef<HTMLDivElement>(null);
  const logoRef = useRef<SVGSVGElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const themeContext = useContext(ThemeContext);

  const [code, setCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { handleReCaptcha } = reCaptchaCheck();

  const handleSuccess = useCallback(() => {
    const logo = logoRef.current;
    const logoWrapper = logoWrapperRef.current;
    const panel = panelRef.current;

    log({
      name: 'User MFA',
      context: {
        phoneNumber,
      },
    });

    signIn()
      .then(() => {
        setAuthChallenge(undefined);
        setPhoneNumber(undefined);

        if (isAuthFlow && params) {
          navigate(PUBLIC.PLAID_SELECT);
          return;
        }

        const url = getRedirectUrlOrRedirect();

        if (url) {
          navigate(url, { replace: true });
          return;
        }

        if (!logo || !logoWrapper || !panel) {
          return;
        }

        animateLogo(logo, logoWrapper, panel, themeContext, gsap, MotionPathPlugin, () =>
          navigate(ROUTE.ROOT, {
            state: { from: 'auth' },
          })
        );
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  }, [logoRef, logoWrapperRef, panelRef, themeContext, isSandbox, params, phoneNumber]);

  const handleSubmit = () => {
    if (loading) {
      return;
    }

    setLoading(true);

    handleReCaptcha(
      (reCaptchaToken: string) => {
        UserRepository.challengeMfa({ code }, reCaptchaToken)
          .then(handleSuccess)
          .catch((error) => {
            if (error.code === 'invalid_session') {
              navigate(PUBLIC.LOGIN, {
                state: {
                  action: 'signOut',
                },
              });
            }

            setLoading(false);

            addDangerNotification({
              content: error.message,
              display: 'page',
            });
          });
      },
      (err) => {
        setLoading(false);

        addDangerNotification({
          content: err.message,
          display: 'page',
        });
      }
    );
  };

  const handleResend = (event: React.MouseEvent) => {
    event.preventDefault();

    handleReCaptcha(
      (reCaptchaToken: string) => {
        UserRepository.resendMfa(reCaptchaToken)
          .then(() => {
            log({
              name: 'User MFA resend',
              context: {
                phoneNumber,
              },
            });

            addSuccessNotification({
              content: `Code sent to ${phoneNumber}`,
              display: 'page',
            });
          })
          .catch((error) => {
            if (error.code === 'invalid_session') {
              navigate(PUBLIC.LOGIN, {
                state: {
                  action: 'signOut',
                },
              });
            }
            addDangerNotification({
              content: error.message,
              display: 'page',
            });
          });
      },
      (err) => {
        setLoading(false);

        addDangerNotification({
          content: err.message,
          display: 'page',
        });
      }
    );
  };

  useEffect(() => {
    if (code && code.length === 6) {
      handleSubmit();
    }
  }, [code]);

  const description = useMemo(() => {
    switch (authChallenge) {
      case ChallengeName.CHALLENGE_MFA_SMS:
        return `Please enter the 6-digit code we have sent to ${phoneNumber}.`;
      case ChallengeName.CHALLENGE_MFA_TOTP:
        return 'Please enter the 6-digit code from your authenticator app.';
      default:
        return undefined;
    }
  }, [authChallenge, phoneNumber]);

  return (
    <>
      {!isAuthFlow && (
        <LogoWrapper ref={logoWrapperRef}>
          <AdditionalLogo size="24px" variant="full" ref={logoRef} />
        </LogoWrapper>
      )}
      <StyledPanel ref={panelRef}>
        {!isAuthFlow && <HiddenLogo size="24px" />}
        <Text>
          <Headline size="small">Authenticate</Headline>
          <Paragraph>{description}</Paragraph>
        </Text>
        <Form>
          <StyledNotificationList display="page" />

          <Rows>
            <CodeInput
              placeholder="######"
              autoFocus
              type="number"
              value={code}
              onChange={(value: string) => setCode(value)}
              isLoading={loading}
            />
          </Rows>
          {authChallenge === ChallengeName.CHALLENGE_MFA_SMS && (
            <Rows>
              <Hint>
                Haven’t received the code? <Resend onClick={handleResend}>Resend</Resend>
              </Hint>
            </Rows>
          )}
        </Form>
      </StyledPanel>
    </>
  );
};
