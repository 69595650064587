import { ReactNode } from 'react';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { generateRandomString } from '~/util';

export type NotificationGroupType = 'page' | 'popup' | string;

export interface NotificationPropsType {
  content: string | ReactNode;
  color?: 'success' | 'warning' | 'danger' | 'info';
  variant?: 'default' | 'light';
  timeout?: number;
  display: NotificationGroupType;
  actionLabel?: string;
  onActionClick?: () => void;
  index?: number;
}

export interface NotificationType extends NotificationPropsType {
  id: string;
  timeout: number;
  openedAt: number;
  timeoutId?: number;
  shouldClose?: boolean;
}

interface NotificationStoreState {
  openNotifications: NotificationType[];
  triggerNotification: NotificationGroupType | false;
}

interface NotificationStoreComputed {
  getOpenNotification: (display?: NotificationGroupType) => NotificationType[];
}

interface NotificationStoreAction {
  addNotification: (notification: NotificationPropsType) => void;
  addSuccessNotification: (notification: NotificationPropsType) => void;
  addDangerNotification: (notification: NotificationPropsType) => void;
  addWarningNotification: (notification: NotificationPropsType) => void;
  addInfoNotification: (notification: NotificationPropsType) => void;
  closeNotification: (idOrNotification?: string | NotificationType) => void;
  removeNotification: (idOrNotification?: string | NotificationType) => void;
  pauseAutocloseNotification: (idOrNotification: string | NotificationType) => void;
  queueAutocloseNotification: (idOrNotification: string | NotificationType) => void;
}

const getIdFromIdOrNotification = (idOrNotification: string | NotificationType) => {
  return typeof idOrNotification === 'string' ? idOrNotification : idOrNotification.id;
};

export const useNotificationStore = create<
  NotificationStoreState & NotificationStoreComputed & NotificationStoreAction
>()(
  subscribeWithSelector((set, get) => ({
    openNotifications: [],
    triggerNotification: false,

    getOpenNotification: (display) => {
      if (display) {
        return get().openNotifications.filter((entry: NotificationType) => entry.display === display);
      }
      return get().openNotifications;
    },

    addNotification: (notification) => {
      if (
        typeof notification.content === 'undefined' ||
        get().openNotifications.some((entry) => entry.content === notification.content)
      ) {
        return;
      }

      const id = generateRandomString();

      set((state) => ({
        openNotifications: [
          ...state.openNotifications,
          {
            id,
            timeout: 5000,
            openedAt: Date.now(),
            ...notification,
          },
        ],
        triggerNotification: notification.display,
      }));

      setTimeout(
        () =>
          set(() => ({
            triggerNotification: false,
          })),
        50
      );

      get().queueAutocloseNotification(id);
    },

    addSuccessNotification: (notification: NotificationPropsType) => {
      get().addNotification({ color: 'success', ...notification });
    },

    addDangerNotification: (notification: NotificationPropsType) => {
      get().addNotification({ color: 'danger', ...notification });
    },

    addWarningNotification: (notification: NotificationPropsType) => {
      get().addNotification({ color: 'warning', ...notification });
    },

    addInfoNotification: (notification: NotificationPropsType) => {
      get().addNotification({ color: 'info', ...notification });
    },

    closeNotification: (idOrNotification?: string | NotificationType) => {
      if (!idOrNotification) {
        set((state) => ({
          openNotifications: state.openNotifications.map((entry: NotificationType) => ({
            ...entry,
            shouldClose: true,
          })),
        }));
        return;
      }

      const id = getIdFromIdOrNotification(idOrNotification);
      set((state) => ({
        openNotifications: state.openNotifications.map((entry: NotificationType) => {
          if (entry.id !== id) {
            return entry;
          }
          return { ...entry, shouldClose: true };
        }),
      }));
    },

    removeNotification: (idOrNotification?: string | NotificationType) => {
      if (typeof idOrNotification === 'undefined') {
        set(() => ({
          openNotifications: [],
        }));
        return;
      }

      const id = getIdFromIdOrNotification(idOrNotification);
      set((state) => ({
        openNotifications: state.openNotifications.filter((entry: NotificationType) => entry.id !== id),
      }));
    },

    pauseAutocloseNotification: (idOrNotification: string | NotificationType) => {
      const id = getIdFromIdOrNotification(idOrNotification);
      const storedNotification = get().openNotifications.find((entry: NotificationType) => entry.id === id);
      if (storedNotification && storedNotification.timeoutId) {
        window.clearTimeout(storedNotification.timeoutId);
        delete storedNotification.timeoutId;
        storedNotification.timeout -= Date.now() - storedNotification.openedAt;
      }
    },

    queueAutocloseNotification: (idOrNotification: string | NotificationType) => {
      const id = getIdFromIdOrNotification(idOrNotification);
      const storedNotification = get().openNotifications.find((entry: NotificationType) => entry.id === id);
      if (storedNotification) {
        storedNotification.openedAt = Date.now();
        storedNotification.timeoutId = window.setTimeout(() => get().closeNotification(id), storedNotification.timeout);
      }
    },
  }))
);
