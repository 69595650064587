import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Button, Icon, Input, SegmentedControl, ToggleHeight } from '@column/column-ui-kit';

import { PopoverBase, PopoverBaseProps } from '../Base';
import { PopoverAction } from '../List';
import { DateInput } from '~/components/DateInput';
import { FormElement, FormLabel } from '~/styles';
import { convertDatesToRange, convertDateToRange, parseDateTime } from '~/util/dateFilterHelpers';

export interface PopoverFilterTransferDateData {
  createdAtLt: string;
  createdAtGte: string;
}

interface PopoverFilterCurrentState {
  date?: string;
  time?: string;
  timeAm?: boolean;
}

interface PopoverFilterTransferStatusProps extends PopoverBaseProps {
  data: Partial<PopoverFilterTransferDateData>;
  onSubmit?: (data: Partial<PopoverFilterTransferDateData>) => void;
}

const Wrapper = styled(PopoverBase)`
  --popover-padding: 0px;
  --popover-width: 248px;
  --popover-left: 0px;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 12px 12px 0 12px;
`;

const Content = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const TimeWrapper = styled.div`
  position: relative;
  min-height: 16px;
`;

const TimeButton = styled(Button)<{ $isHidden?: boolean }>`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  transition:
    opacity 0.2s,
    color 0.2s;

  opacity: ${({ $isHidden }) => ($isHidden ? 0 : 1)};
  pointer-events: ${({ $isHidden }) => ($isHidden ? 'none' : 'auto')};
`;

const TimeContent = styled(ToggleHeight)`
  --toggle-height-padding: 0;
`;

const TimeFields = styled.div`
  display: grid;
  grid-template-columns: auto 83px;
  grid-gap: 8px;
  align-items: center;
`;

const StyledSegmentedControl = styled(SegmentedControl)`
  button {
    padding: 5px 8px;
  }
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(150)};
`;

export const PopoverFilterTransferDate: React.FC<PopoverFilterTransferStatusProps> = ({
  data,
  onSubmit,
  ...popoverProps
}) => {
  const [currentTab, setCurrentTab] = useState<'exact' | 'range'>('exact');

  const [date, setDate] = useState<Partial<PopoverFilterCurrentState>>({});
  const [startDate, setStartDate] = useState<Partial<PopoverFilterCurrentState>>({});
  const [endDate, setEndDate] = useState<Partial<PopoverFilterCurrentState>>({});

  const [dateShowTime, setDateShowTime] = useState<boolean>(false);
  const [startDateShowTime, setStartDateShowTime] = useState<boolean>(false);
  const [endDateShowTime, setEndDateShowTime] = useState<boolean>(false);

  useEffect(() => {
    if (data?.createdAtGte && data?.createdAtLt) {
      const startInfo = parseDateTime(data.createdAtGte);
      const endInfo = parseDateTime(data.createdAtLt, true);

      if (startInfo.date === endInfo.date) {
        setDate(startInfo);
        setCurrentTab('exact');
        setDateShowTime(true);
        setStartDate({});
        setEndDate({});
      } else {
        setDate({});
        setStartDate(startInfo);
        setStartDateShowTime(true);
        setEndDate(endInfo);
        setEndDateShowTime(true);
        setCurrentTab('range');
      }
    } else {
      setDate({});
      setStartDate({});
      setEndDate({});
      setCurrentTab('exact');
    }
  }, [data, popoverProps.show]);

  const handleResetClick = useCallback(() => {
    setDate({});
    setStartDate({});
    setEndDate({});
    setCurrentTab('exact');

    if (onSubmit) {
      onSubmit({
        createdAtGte: undefined,
        createdAtLt: undefined,
      });
    }
  }, [onSubmit]);

  const handleApplyClick = useCallback(() => {
    if (!onSubmit) {
      return;
    }

    if (currentTab === 'exact') {
      const result = convertDateToRange(date.date, date.time, date.timeAm);

      onSubmit(result);
    } else {
      const result = convertDatesToRange(
        startDate.date,
        startDate.time,
        startDate.timeAm,
        endDate.date,
        endDate.time,
        endDate.timeAm
      );

      onSubmit(result);
    }
  }, [onSubmit, date, startDate, endDate, currentTab]);

  const isValidTime = useCallback((value?: string) => {
    if (typeof value === 'undefined' || !value) {
      return true;
    }

    const timeValue = value?.replace(':', '');

    if (timeValue.length !== 4) {
      return false;
    }

    const numberValue = Number(timeValue);

    return numberValue >= 0 && numberValue <= 1259;
  }, []);

  const isDisabled = useMemo(() => {
    const isNotValid = (value: Partial<PopoverFilterCurrentState>, showTime: boolean) => {
      return !value?.date || (showTime && value?.time && !isValidTime(value?.time));
    };

    if (currentTab === 'exact') {
      return isNotValid(date, dateShowTime);
    }

    if (currentTab === 'range') {
      return isNotValid(startDate, startDateShowTime) || isNotValid(endDate, endDateShowTime);
    }

    return false;
  }, [date, startDate, endDate, currentTab, isValidTime, dateShowTime, startDateShowTime, endDateShowTime]);

  const handleTimeChange = (value: string, key: 'date' | 'startDate' | 'endDate') => {
    let formatted = value.replace(/[^0-9:]/g, '');

    if (formatted.length > 2 && !formatted.includes(':')) {
      formatted = `${formatted.substring(0, 2)}:${formatted.substring(2)}`;
    }

    formatted = formatted.slice(0, 5);

    if (key === 'date') {
      setDate((s) => ({ ...s, time: formatted }));

      return;
    }

    if (key === 'startDate') {
      setStartDate((s) => ({ ...s, time: formatted }));

      return;
    }

    if (key === 'endDate') {
      setEndDate((s) => ({ ...s, time: formatted }));

      return;
    }
  };

  return (
    <Wrapper {...popoverProps}>
      <Grid key={currentTab}>
        <SegmentedControl
          size="small"
          options={[
            {
              label: 'Exact',
              value: 'exact',
            },
            {
              label: 'Range',
              value: 'range',
            },
          ]}
          fullWidth
          active={currentTab}
          onOptionChange={setCurrentTab}
        />
        {currentTab !== 'range' ? (
          <Content key="exact">
            <FormElement>
              <FormLabel>Created at</FormLabel>
              <DateInput
                size="small"
                value={date?.date}
                onChange={(value) => setDate((s) => ({ ...s, date: value }))}
              />
            </FormElement>
            <TimeWrapper>
              <TimeButton
                variant="subtle-primary"
                size="small"
                onClick={() => setDateShowTime(true)}
                icon={<Icon.Plus />}
                $isHidden={dateShowTime}
              >
                Add time
              </TimeButton>
              <TimeContent isClose={!dateShowTime}>
                <FormElement>
                  <FormLabel>Time</FormLabel>
                  <TimeFields>
                    <Input
                      placeholder="00:00"
                      value={date?.time ?? ''}
                      onChange={(value) => handleTimeChange(value, 'date')}
                      hasError={!isValidTime(date?.time)}
                      size="small"
                    />
                    <StyledSegmentedControl
                      options={[
                        {
                          label: 'AM',
                          value: true,
                        },
                        {
                          label: 'PM',
                          value: false,
                        },
                      ]}
                      size="small"
                      active={date.timeAm ?? true}
                      onOptionChange={(value: boolean | undefined) => setDate((s) => ({ ...s, timeAm: value }))}
                    />
                  </TimeFields>
                </FormElement>
              </TimeContent>
            </TimeWrapper>
          </Content>
        ) : (
          <Content key="range">
            <FormElement>
              <FormLabel>Start Date</FormLabel>
              <DateInput
                size="small"
                value={startDate?.date}
                onChange={(value) => setStartDate((s) => ({ ...s, date: value }))}
              />
            </FormElement>
            <TimeWrapper>
              <TimeButton
                variant="subtle-primary"
                size="small"
                onClick={() => setStartDateShowTime(true)}
                icon={<Icon.Plus />}
                $isHidden={startDateShowTime}
              >
                Add time
              </TimeButton>
              <TimeContent isClose={!startDateShowTime}>
                <FormElement>
                  <FormLabel>Time</FormLabel>
                  <TimeFields>
                    <Input
                      placeholder="00:00"
                      value={startDate?.time ?? ''}
                      onChange={(value) => handleTimeChange(value, 'startDate')}
                      hasError={!isValidTime(startDate?.time)}
                      size="small"
                    />
                    <StyledSegmentedControl
                      options={[
                        {
                          label: 'AM',
                          value: true,
                        },
                        {
                          label: 'PM',
                          value: false,
                        },
                      ]}
                      size="small"
                      active={startDate?.timeAm ?? true}
                      onOptionChange={(value: boolean | undefined) => setStartDate((s) => ({ ...s, timeAm: value }))}
                    />
                  </TimeFields>
                </FormElement>
              </TimeContent>
            </TimeWrapper>

            <Line />

            <FormElement>
              <FormLabel>End Date</FormLabel>
              <DateInput
                size="small"
                value={endDate?.date}
                onChange={(value) => setEndDate((s) => ({ ...s, date: value }))}
              />
            </FormElement>
            <TimeWrapper>
              <TimeButton
                variant="subtle-primary"
                size="small"
                onClick={() => setEndDateShowTime(true)}
                icon={<Icon.Plus />}
                $isHidden={endDateShowTime}
              >
                Add time
              </TimeButton>
              <TimeContent isClose={!endDateShowTime}>
                <FormElement>
                  <FormLabel>Time</FormLabel>
                  <TimeFields>
                    <Input
                      placeholder="00:00"
                      value={endDate?.time ?? ''}
                      onChange={(value) => handleTimeChange(value, 'endDate')}
                      hasError={!isValidTime(endDate?.time)}
                      size="small"
                    />
                    <StyledSegmentedControl
                      options={[
                        {
                          label: 'AM',
                          value: true,
                        },
                        {
                          label: 'PM',
                          value: false,
                        },
                      ]}
                      size="small"
                      active={endDate?.timeAm ?? true}
                      onOptionChange={(value: boolean | undefined) => setEndDate((s) => ({ ...s, timeAm: value }))}
                    />
                  </TimeFields>
                </FormElement>
              </TimeContent>
            </TimeWrapper>
          </Content>
        )}
      </Grid>
      <PopoverAction>
        <Button size="small" variant="secondary" onClick={handleResetClick}>
          Reset
        </Button>
        <Button size="small" onClick={handleApplyClick} isDisabled={!!isDisabled}>
          Apply
        </Button>
      </PopoverAction>
    </Wrapper>
  );
};
