import React, { FC, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button } from '@column/column-ui-kit';

import { IconTransfers } from '~/elements/icons/transfers';
import { useModalStore, ModalType } from '~/stores/Modal';
import { Headline, Paragraph } from '~/styles';

import { Wrapper } from './Authenticate';

const ErrorCheck = styled(Wrapper)`
  --modal-width: 520px;
`;

const Boxes = styled.div`
  margin-top: 24px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`;

const Box = styled.div`
  svg {
    --icon-size: 48px;
  }

  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
  padding: 16px;
  border-radius: 11px;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(200)};

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      box-shadow: inset 0 0 0 1px ${theme.secondary.blendToBackground(100)};
    `}
`;

const BoxText = styled.div`
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
`;

const BoxHeadline = styled(Headline)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`;

const Continue = styled(Button)`
  margin-top: auto;
`;

export const ModalErrorCheck: FC = () => {
  const { closeModal } = useModalStore();
  const modalTypes: ModalType[] = ['ErrorCheck'];
  const [modalDataState, setModalDataState] = useState<{ callback?: () => void }>({});

  const handleSuccess = useCallback(() => {
    if (modalDataState.callback) {
      modalDataState.callback();
    }

    closeModal('ErrorCheck');
  }, [modalDataState]);

  useEffect(
    () =>
      useModalStore.subscribe(
        (state) => state.getModalData(),
        (modalData) => {
          const data = modalData;

          if (!data) {
            return;
          }

          setModalDataState(data);
        },
        {
          fireImmediately: true,
        }
      ),
    []
  );

  return (
    <ErrorCheck modalTypes={modalTypes}>
      <Headline>Jam!</Headline>
      <Boxes>
        <Box>
          <IconTransfers.CheckJam />
          <BoxText>
            <BoxHeadline>Step 1</BoxHeadline>
            <Paragraph size="small">
              Don't refresh the page, locate and clear the jammed check and return to the loading tray.
            </Paragraph>
          </BoxText>
        </Box>
        <Box>
          <IconTransfers.CheckContinue />
          <BoxText>
            <BoxHeadline>Step 2</BoxHeadline>
            <Paragraph size="small">Continue scanning and cross your fingers everything works this time.</Paragraph>
          </BoxText>
          <Continue onClick={handleSuccess} fullWidth>
            Continue scanning
          </Continue>
        </Box>
      </Boxes>
    </ErrorCheck>
  );
};
