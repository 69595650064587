import React, { FC, ReactNode, useCallback } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { SegmentedControl, SegmentedControlOptionProps, SegmentedControlOption } from '@column/column-ui-kit';

interface Entry extends Partial<SegmentedControlOptionProps> {
  path: string;
  hide?: boolean;
  activePath?: string;
}

interface PageTabNavigationProps {
  entries?: Entry[];
  className?: string;
}

interface PageProps {
  headline?: string;
  entries?: Entry[];
  Component?: ReactNode;
}

const PageWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledSegmentedControl = styled(SegmentedControl)`
  margin: 0;
  gap: 8px;
  border: 0;
  box-shadow: none;
  display: flex;

  &::before {
    transition:
      left 0.05s ease-out,
      width 0.02s ease-out;
  }

  &::before {
    height: auto;
    top: 0;
    bottom: 0;
    z-index: 0;
    --option-active-background: ${({ theme }) => theme.primary.blendToBackground(200)};
    border-radius: 8px;
    box-shadow: none;

    ${({ theme }) =>
      theme.id !== 'default' &&
      css`
        --option-active-background: ${theme.primary.blendToBackground(1000, 200)};
      `}
  }

  ${SegmentedControlOption} {
    padding: 4px 12px;
    line-height: 1;
    z-index: 1;
    position: relative;
    height: 32px;
    box-shadow: none;
    --option-color: ${({ theme }) => theme.secondary.background};
    --option-active-color: ${({ theme }) => theme.blue.background};

    ${({ theme }) =>
      theme.id !== 'default' &&
      css`
        --option-color: ${theme.secondary.background};
        --option-active-color: ${theme.primary.blendToBackground(1300)};
      `}
  }
`;

export const PageTabNavigation: FC<PageTabNavigationProps> = ({ className, entries }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const handleNavChange = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  const replaceParams = useCallback(
    (path: string) => {
      let newPath = path;
      Object.keys(params).forEach((key) => {
        const value = params[key];
        if (typeof value === 'string') {
          newPath = newPath.replace(`:${key}`, value);
        }
      });
      return newPath;
    },
    [params]
  );

  if (!entries) {
    return null;
  }

  return (
    <StyledSegmentedControl
      className={className}
      active={location.pathname}
      activeWildcard
      options={
        entries.map(
          (entry: Entry) =>
            !entry.hide && {
              label: entry.label === 'Edit' ? 'Account Details' : entry.label,
              value: replaceParams(entry.path),
              removeWildcard: entry.removeWildcard,
            }
        ) as SegmentedControlOptionProps[]
      }
      onOptionChange={handleNavChange}
      line
    />
  );
};

export const Page: React.FC<PageProps> = () => {
  return (
    <PageWrapper>
      <Outlet />
    </PageWrapper>
  );
};
