import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { Button, ButtonProps, Icon } from '@column/column-ui-kit';

import { DocumentRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { downloadFile } from '~/util';

interface DownloadButtonProps extends ButtonProps {
  label: string;
  fileName: string;
  id?: string;
}

const StyledButton = styled(Button)`
  font-size: 13px;
`;

export const DownloadButton: React.FC<DownloadButtonProps> = ({ label, fileName, id, ...buttonProps }) => {
  const { addDangerNotification } = useNotificationStore();

  const [running, setRunning] = useState<boolean>(false);

  const handleDownload = useCallback(
    (docId?: string) => {
      if (!docId) {
        return;
      }

      setRunning(true);

      DocumentRepository.get(docId)
        .then((response) => downloadFile(response.url, fileName))
        .catch((error) =>
          addDangerNotification({
            content: error.message,
            display: 'page',
          })
        );
    },
    [fileName, addDangerNotification]
  );

  return (
    <StyledButton
      onClick={(e: React.MouseEvent) => {
        handleDownload(id);
        e.stopPropagation();
      }}
      isDisabled={!id}
      size="small"
      icon={<Icon.AnimationDownload running={running} onDone={() => setRunning(false)} />}
      {...buttonProps}
    >
      {label}
    </StyledButton>
  );
};
