import React, { useCallback, useEffect, useReducer, useState } from 'react';
import QRCode from 'react-qr-code';
import styled, { useTheme } from 'styled-components';

import { Button, Fade, Icon, Tooltip } from '@column/column-ui-kit';

import { CodeInput, NotificationList } from '~/components';
import { Modal } from '~/components/ModalV2';
import { LogoLoading } from '~/elements';
import { UserModel } from '~/models';
import { FactorType, MFAStatus } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Box, Grid } from '~/styles';
import { copyToClipboard } from '~/util';
import { useReCaptcha } from '~/util/reCaptcha';

interface Props {
  onClose: () => void;
  onComplete: () => void;
  onError: () => void;
  open: boolean;
}

const ModalDimensions = {
  width: '100%',
  height: '275px',
};

const Loading: React.FC = () => {
  return (
    <div
      style={{
        position: 'relative',
        height: ModalDimensions.height,
        width: '100%',
      }}
    >
      <div style={{ position: 'relative', top: 'calc(50% - 24px)' }}>
        <LogoLoading />
      </div>
    </div>
  );
};

interface ActionInit {
  action: 'Init';
}

interface ActionStartFactorSetup {
  action: 'StartFactorSetup';
  user: UserModel;
}

interface ActionCompleteFactorSetup {
  action: 'CompleteFactorSetup';
  factorId: string;
  otpauthUri: string;
}

interface ActionStartFactorVerification {
  action: 'StartFactorVerification';
}

interface ActionFailFactorVerification {
  action: 'FailFactorVerification';
}

interface ActionError {
  action: 'Error';
  message: string;
}

type Action =
  | ActionInit
  | ActionStartFactorSetup
  | ActionCompleteFactorSetup
  | ActionStartFactorVerification
  | ActionFailFactorVerification
  | ActionError;

interface StateInit {
  state: 'Init';
}

interface StateAwaitingSetup {
  state: 'AwaitingSetup';
  user: UserModel;
}

interface StateAwaitingCodeInput {
  state: 'AwaitingCodeInput';
  factorId: string;
  otpauthUri: string;
  user: UserModel;
}

interface StateAwaitingVerification {
  state: 'AwaitingVerification';
  factorId: string;
  otpauthUri: string;
  user: UserModel;
}

interface StateError {
  state: 'Error';
  message: string;
}

type State = StateInit | StateAwaitingSetup | StateAwaitingCodeInput | StateAwaitingVerification | StateError;

const InitState: StateInit = { state: 'Init' };

const mfaSetupReducer = (state: State, action: Action): State => {
  switch (action.action) {
    case 'Init':
      return { state: 'Init' };
    case 'StartFactorSetup':
      return { state: 'AwaitingSetup', user: action.user };
    case 'CompleteFactorSetup':
      if (state.state !== 'AwaitingSetup') {
        return { state: 'Error', message: 'Invalid state' };
      }
      return {
        state: 'AwaitingCodeInput',
        factorId: action.factorId,
        otpauthUri: action.otpauthUri,
        user: state.user,
      };
    case 'StartFactorVerification':
      if (state.state !== 'AwaitingCodeInput') {
        return { state: 'Error', message: 'Invalid state' };
      }
      return {
        state: 'AwaitingVerification',
        factorId: state.factorId,
        otpauthUri: state.otpauthUri,
        user: state.user,
      };
    case 'FailFactorVerification':
      if (state.state !== 'AwaitingVerification') {
        return { state: 'Error', message: 'Invalid state' };
      }
      return {
        state: 'AwaitingCodeInput',
        factorId: state.factorId,
        otpauthUri: state.otpauthUri,
        user: state.user,
      };
    case 'Error':
      return { state: 'Error', message: action.message };
  }
};

export const SetupMfaTotpModal: React.FC<Props> = ({ onClose, onComplete, onError, open }) => {
  const { addDangerNotification, addSuccessNotification } = useNotificationStore();
  const { currentUser } = useSessionStore();
  const getReCaptchaToken = useReCaptcha();

  const [verificationCode, setVerificationCode] = useState<string>('');
  const [mfaSetupState, dispatch] = useReducer(mfaSetupReducer, InitState);
  const setup = useCallback(
    async (user: UserModel) => {
      const reCaptchaToken = await getReCaptchaToken();

      let factorId, otpauthUri;
      try {
        const resp = await user.setupMfa(reCaptchaToken, {
          factorName: user.email,
          factorType: FactorType.TOTP,
        });
        factorId = resp.factorId;
        otpauthUri = resp.otpauthUri;
      } catch (error) {
        dispatch({ action: 'Error', message: (error as Error).message });
        return;
      }
      if (!factorId || !otpauthUri) {
        dispatch({ action: 'Error', message: 'An error occurred during setup. Please try again.' });
        return;
      }
      dispatch({ action: 'CompleteFactorSetup', factorId, otpauthUri });
    },
    [getReCaptchaToken]
  );
  const verify = useCallback(
    async (user: UserModel, code: string, factorId: string) => {
      const reCaptchaToken = await getReCaptchaToken();

      let mfaStatus: MFAStatus;
      try {
        const resp = await user.verifyMfa(reCaptchaToken, {
          code,
          factorId,
        });
        mfaStatus = resp.mfaStatus;
      } catch (error) {
        let content = (error as Error)?.message ?? 'An error occurred';
        if (content === 'Incorrect login mfa code.') {
          content = 'Invalid verification code';
        }
        addDangerNotification({
          content,
          display: 'popup',
        });
        dispatch({ action: 'FailFactorVerification' });
        setVerificationCode('');
        return;
      }
      setVerificationCode('');

      if (mfaStatus !== MFAStatus.VERIFIED) {
        addDangerNotification({
          content: 'Failed to verify. Please try again.',
          display: 'popup',
        });
        dispatch({ action: 'FailFactorVerification' });
        return;
      }

      addSuccessNotification({
        content: 'Authenticator app enabled',
        display: 'page',
      });
      dispatch({ action: 'Init' });
      onComplete();
    },
    [getReCaptchaToken]
  );

  useEffect(() => {
    if (currentUser && open) {
      dispatch({ action: 'StartFactorSetup', user: currentUser });
    }
  }, [currentUser, open]);

  useEffect(() => {
    if (mfaSetupState.state === 'AwaitingSetup') {
      setup(mfaSetupState.user);
    }
  }, [mfaSetupState]);

  useEffect(() => {
    if (mfaSetupState.state === 'AwaitingCodeInput' && verificationCode.length === 6) {
      dispatch({ action: 'StartFactorVerification' });
      verify(mfaSetupState.user, verificationCode, mfaSetupState.factorId);
    }
  }, [mfaSetupState, verificationCode]);

  useEffect(() => {
    if (mfaSetupState.state === 'Error') {
      addDangerNotification({
        content: mfaSetupState.message,
        display: 'page',
      });
      onError();
    }
  }, [mfaSetupState, onError]);

  return (
    <Modal
      headline={'Setup Authenticator App'}
      icon={<Icon.Key />}
      onClose={onClose}
      open={open}
      size={Modal.Size.Medium}
    >
      <NotificationList display={'popup'} />
      <div style={ModalDimensions}>
        <Fade show={mfaSetupState.state === 'Init' || mfaSetupState.state === 'AwaitingSetup'}>
          <Loading />
        </Fade>
        <Fade show={mfaSetupState.state === 'AwaitingCodeInput' || mfaSetupState.state === 'AwaitingVerification'}>
          {(mfaSetupState.state === 'AwaitingCodeInput' || mfaSetupState.state === 'AwaitingVerification') && (
            <VerificationView
              code={verificationCode}
              isLoading={mfaSetupState.state === 'AwaitingVerification'}
              onCodeChange={setVerificationCode}
              otpauthUri={mfaSetupState.otpauthUri}
            />
          )}
        </Fade>
      </div>
    </Modal>
  );
};

const CodeInputDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const StyledTooltip = styled(Tooltip)`
  display: inline;
  width: 100%;
`;

const VerificationOverview = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: center;
  width: 100%;

  p {
    max-width: 440px;
    margin: 0;
    text-align: center;
    color: ${({ theme }) => theme.secondary.background};
  }
`;

const MfaWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
`;

const MfaQRCode = styled.div`
  border-right: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
  padding: 24px;
`;

const MfaInput = styled.div`
  padding: 24px;
`;

const MfaSetupCode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;

  p {
    margin: 0 0 2px;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  }
`;

interface VerificationViewProps {
  code: string;
  isLoading: boolean;
  onCodeChange: (code: string) => void;
  otpauthUri: string;
}

const VerificationView: React.FC<VerificationViewProps> = ({ code, isLoading, onCodeChange, otpauthUri }) => {
  const [showCopied, setShowCopied] = useState<boolean>(false);

  const handleCopySetupCode = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      setShowCopied(true);
      copyToClipboard(otpauthUri);
      setTimeout(() => {
        setShowCopied(false);
      }, 550);
    },
    [otpauthUri]
  );

  const theme = useTheme();

  return (
    <Grid vertical>
      <VerificationOverview>
        <p>
          Scan the QR code below with your preferred authenticator app. We recommend 1Password, Authy, or Google
          Authenticator.
        </p>
      </VerificationOverview>
      <Box variant="secondary">
        <MfaWrapper>
          <MfaQRCode>
            <QRCode fgColor={theme.foreground} bgColor="transparent" size={150} value={otpauthUri} />
          </MfaQRCode>
          <MfaInput>
            <CodeInputDescription>Enter the code generated by your app</CodeInputDescription>
            <CodeInput
              autoFocus
              isLoading={isLoading}
              placeholder="######"
              type="number"
              value={code}
              onChange={onCodeChange}
            />

            <MfaSetupCode>
              <p>If your authenticator doesn't recognize the QR code, copy this setup code</p>
              <StyledTooltip content={'Copied!'} placement={'bottom'} zIndex={100} isOpen={showCopied}>
                <Button variant="muted" size="tiny" onClick={handleCopySetupCode}>
                  Copy setup code
                </Button>
              </StyledTooltip>
            </MfaSetupCode>
          </MfaInput>
        </MfaWrapper>
      </Box>
    </Grid>
  );
};
