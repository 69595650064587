import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ROUTE } from '~/public/routes';
import { useSessionStore } from '~/stores/Session';

interface CenterProps {
  size?: 'default' | 'middle' | 'large';
  verticalCenter?: boolean;
  loggedIn?: boolean;
}

const Wrapper = styled.div<CenterProps>`
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;

  align-self: ${({ verticalCenter }) => verticalCenter && 'center'};

  ${({ size }) =>
    size === 'middle' &&
    css`
      max-width: 460px;
    `}

  ${({ size }) =>
    size === 'large' &&
    css`
      max-width: 680px;
    `}
`;

export const Center: React.FC<CenterProps> = ({ loggedIn, ...restProps }) => {
  const navigate = useNavigate();

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({ isSignedIn: state.isSignedIn(), isLoading: state.isLoading }),
        ({ isSignedIn, isLoading }) => {
          if (isLoading) {
            return;
          }

          if (!isSignedIn && loggedIn) {
            navigate(ROUTE.LOGIN, {
              state: {
                from: location,
                action: 'signOut',
              },
            });
          }
        },
        {
          fireImmediately: true,
        }
      ),
    [location.pathname, loggedIn]
  );

  return (
    <Wrapper {...restProps}>
      <Outlet />
    </Wrapper>
  );
};
