import { LDContext, useLDClient } from 'launchdarkly-react-client-sdk';
import { FC, PropsWithChildren, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { useSessionStore } from '~/stores/Session';
import { log } from '~/util';

export const LDClientIdentifier: FC<PropsWithChildren> = ({ children }) => {
  const ldClient = useLDClient();

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => [state.currentUser?.id, state.currentUser?.defaultPlatformId, state.isSandbox],
        (state) => {
          const [userId, platformId, isSandbox] = state as [
            string | undefined,
            string | undefined,
            boolean | undefined,
          ];

          if (!userId || !platformId || !ldClient) {
            return;
          }

          const context: LDContext = {
            kind: 'multi',
            user: {
              kind: 'user',
              key: userId,
            },
            platform: {
              kind: 'platform',
              key: platformId,
              mode: isSandbox ? 'sandbox' : 'live',
            },
          };

          ldClient
            .identify(context)
            .then(() => {
              log({ name: 'Identify success', type: 'info', context });
            })
            .catch((error) => {
              log({ name: 'Identify error', type: 'error', context: { context, error } });
            });
        },
        {
          fireImmediately: true,
          equalityFn: shallow,
        }
      ),
    [ldClient]
  );

  return children;
};
