import React, { useEffect, useState, ReactNode, PropsWithChildren, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Button, DropdownElement, Icon, Radio, ToggleHeight } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { CopyInput, AddressField, RestrictedBanner, PageHeader, SectionHeader } from '~/components';
import { useAddress } from '~/hooks';
import {
  AddressType,
  EntityRepository,
  CreatePersonEntity,
  CreateBusinessEntity,
  PersonDetails,
  UpdateBusinessEntity,
} from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Divider, EditToolbar, FormElement, FormLabel, Grid, Inner, Box } from '~/styles';
import { copy, generateFields } from '~/util';

export type EntityTypes = 'person' | 'business';

interface Params {
  id: string;
  type: EntityTypes;
}

export const EntityType = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`;

const Type = styled.div`
  cursor: pointer;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(150)};
  transition: box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 12px;
  padding: 20px 12px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.background};

  &:hover {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(300)};
  }
`;

const TypeRadio = styled(Radio)`
  position: absolute;
  right: 12px;
  top: 12px;
`;

export const EntityTypeOptionLabel = styled.div<{ isChecked: boolean }>`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  transition: color 0.2s;

  ${({ isChecked }) =>
    isChecked &&
    css`
      color: ${({ theme }) => theme.primary.background};
    `};
`;

export const EntityTypeOptionCircle = styled.div<{ isChecked: boolean }>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.secondary.blendToBackground(500)};
  background-color: ${({ theme }) => theme.secondary.blendToBackground(75)};
  transition:
    color 0.2s,
    background-color 0.2s;

  svg {
    --icon-size: 22px;
    --icon-color: currentColor;
  }

  ${({ isChecked }) =>
    isChecked &&
    css`
      color: ${({ theme }) => theme.primary.background};
      background-color: ${({ theme }) => theme.primary.blendToBackground(1000, 100)};
    `};
`;

const ToggleTitle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 1px;
  padding: 16px 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.foreground};

  span {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  sup {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 24px;
    height: 24px;
    border-radius: 16px;
    background: ${({ theme }) => theme.background};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(150)};
    color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }
`;

const Up = styled(Icon.ChevronUpLarge)`
  cursor: pointer;
  margin-left: auto;
`;

const Down = styled(Icon.ChevronDownLarge)`
  cursor: pointer;
  margin-left: auto;
`;

interface EntityTypeOptionProps {
  isChecked: boolean;
  onCheckedChange: (value: boolean) => void;
}

export const EntityTypeOption: React.FC<PropsWithChildren<EntityTypeOptionProps>> = (props) => {
  const handleClick = () => {
    props.onCheckedChange(true);
  };

  return (
    <Type onClick={handleClick}>
      <TypeRadio isChecked={props.isChecked ?? false} onCheckedChange={props.onCheckedChange} />
      {props.children}
    </Type>
  );
};

export const EntityTypeIcons: Record<EntityTypes, ReactNode> = {
  person: <Icon.User />,
  business: <Icon.Business />,
};

export const PageEntitiesEdit: React.FC = () => {
  const { currentUser, currentPlatform, currentPermission } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const { id, type } = useParams<keyof Params>() as Params;
  const [person, setPerson] = useState<DeepPartial<CreatePersonEntity>>({});
  const [business, setBusiness] = useState<DeepPartial<CreateBusinessEntity>>({});
  const [businessBeneficialOwner, setBusinessBeneficialOwner] = useState<DeepPartial<PersonDetails[]>>([{}]);
  const [businessBeneficialOwnerOpen, setBusinessBeneficialOwnerOpen] = useState<number[]>([0]);
  const { states, countries } = useAddress({ types: ['state', 'country'] });
  const personStateRef = useRef<DropdownElement>(null);
  const businessStateRef = useRef<DropdownElement>(null);
  const beneficialOwnerStateRef = useRef<DropdownElement>(null);

  const handleBeneficialOwner = (object: DeepPartial<PersonDetails>, index: number) => {
    const beneficialOwner = copy(businessBeneficialOwner);
    beneficialOwner[index] = object;
    setBusinessBeneficialOwner(beneficialOwner as DeepPartial<PersonDetails[]>);
  };

  const addBeneficialOwner = () => {
    setBusinessBeneficialOwner([...businessBeneficialOwner, { isNew: true }]);

    setBusinessBeneficialOwnerOpen([businessBeneficialOwner.length]);
  };

  const handleToggle = (index: number) => {
    const entries = copy(businessBeneficialOwnerOpen);
    if (entries.includes(index)) {
      const entry = entries.indexOf(index);
      if (entry !== -1) {
        entries.splice(entry, 1);
        setBusinessBeneficialOwnerOpen(entries);
      }
      return;
    }
    entries.push(index);
    setBusinessBeneficialOwnerOpen(entries);
  };

  const handleSave = () => {
    if (!id || !currentUser) {
      return;
    }

    if (type === 'person') {
      EntityRepository.updatePerson(id, {
        firstName: person.firstName,
        lastName: person.lastName,
        middleName: person.middleName,
        dateOfBirth: person.dateOfBirth,
        email: person.email,
        address: person.address,
      })
        .then((response) => {
          addSuccessNotification({
            content: `${response.personDetails?.firstName} ${response.personDetails?.lastName} updated`,
            display: 'page',
          });
        })
        .catch((error) => {
          addDangerNotification({
            content: error.message,
            display: 'page',
          });
        });
    } else {
      delete business.ein;
      delete (business as any).registrationId;

      business.beneficialOwners = businessBeneficialOwner.map((beneficialOwner: DeepPartial<PersonDetails>) => {
        if (!beneficialOwner.driversLicense) {
          delete beneficialOwner.driversLicense;
        }
        if (!beneficialOwner.nationalId) {
          delete beneficialOwner.nationalId;
        }
        if (!beneficialOwner.passport || (!beneficialOwner.passport.number && !beneficialOwner.passport.countryCode)) {
          delete beneficialOwner.passport;
        }
        if (!beneficialOwner.ownershipPercentage || beneficialOwner.ownershipPercentage === 0) {
          delete beneficialOwner.ownershipPercentage;
        }
        delete beneficialOwner.isNew;
        return beneficialOwner;
      });

      if (!business.countriesOfOperation) {
        delete business.countriesOfOperation;
      }

      EntityRepository.updateBusiness(id, business as UpdateBusinessEntity)
        .then((response) => {
          addSuccessNotification({
            content: `${response.businessDetails?.businessName} updated`,
            display: 'page',
          });
        })
        .catch((error) => {
          addDangerNotification({
            content: error.message,
            display: 'page',
          });
        });
    }
  };

  const handleSubmit = () => {
    if (!currentUser) {
      return;
    }

    if (id) {
      handleSave();
      return;
    }

    if (type === 'person') {
      EntityRepository.createPerson(person as CreatePersonEntity)
        .then((response) => {
          navigate(ROUTE.ENTITIES);

          addSuccessNotification({
            content: `${response.personDetails?.firstName} ${response.personDetails?.lastName} added`,
            display: 'page',
          });
        })
        .catch((error) => {
          addDangerNotification({
            content: error.message,
            display: 'page',
          });
        });
    } else {
      business.beneficialOwners = businessBeneficialOwner.filter(
        (v: DeepPartial<PersonDetails>) => Object.keys(v).length !== 0
      );
      EntityRepository.createBusiness(business as CreateBusinessEntity)
        .then((response) => {
          navigate(ROUTE.ENTITIES);

          addSuccessNotification({
            content: `${response.businessDetails?.businessName} added`,
            display: 'page',
          });
        })
        .catch((error) => {
          addDangerNotification({
            content: error.message,
            display: 'page',
          });
        });
    }
  };

  const fetchData = () => {
    EntityRepository.get(id)
      .then((response) => {
        if (response.isRoot) {
          if (currentPlatform?.isLiveEnabled) {
            navigate(ROUTE.PLATFORM);
            return;
          }
          navigate(ROUTE.PLATFORM_ROOT_ENTITY);
        }

        setPerson(response.personDetails ?? {});
        setBusiness(response.businessDetails ?? {});
        setBusinessBeneficialOwner((response.businessDetails && response.businessDetails.beneficialOwners) ?? []);
      })
      .catch((error) => {
        navigate(ROUTE.ENTITIES);
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isSandbox: state.isSandbox,
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => {
          if (!currentUser) {
            return;
          }
          if (id) {
            fetchData();
          }
        }
      ),
    []
  );

  return (
    <>
      <PageHeader text={id ? 'Edit Entity' : 'Create Entity'}>
        <Button onClick={() => navigate(-1)} variant="secondary" size="small">
          {id ? 'Back' : 'Cancel'}
        </Button>
        <Button
          onClick={handleSubmit}
          isDisabled={currentPermission?.entities !== 'write'}
          size="small"
          icon={!id && <Icon.Plus />}
        >
          {id ? 'Save' : 'Create Entity'}
        </Button>
      </PageHeader>

      {!id && (
        <>
          <SectionHeader text="Type" />
          <Inner pt={0}>
            <FormElement>
              <EntityType>
                {['Person', 'Business'].map((name: string) => {
                  const nameLower = name.toLowerCase() as EntityTypes;
                  return (
                    <EntityTypeOption
                      key={name}
                      isChecked={type === nameLower}
                      onCheckedChange={(value: boolean) => value && navigate(`${ROUTE.ENTITIES}/edit/${nameLower}`)}
                    >
                      <EntityTypeOptionCircle isChecked={type === nameLower}>
                        {EntityTypeIcons[nameLower]}
                      </EntityTypeOptionCircle>
                      <EntityTypeOptionLabel isChecked={type === nameLower}>{name}</EntityTypeOptionLabel>
                    </EntityTypeOption>
                  );
                })}
              </EntityType>
            </FormElement>
          </Inner>
        </>
      )}

      {currentPermission?.entities !== 'write' && (
        <Inner>
          <RestrictedBanner />
        </Inner>
      )}

      {type === 'person' && (
        <>
          <SectionHeader text="Information" border={!id} />
          <Inner data-disabled={currentPermission?.entities !== 'write'} pt={16}>
            <Grid>
              {id && (
                <FormElement>
                  <FormLabel>ID</FormLabel>
                  <CopyInput value={id} />
                </FormElement>
              )}
              {generateFields<CreatePersonEntity>(
                [
                  {
                    label: 'First Name',
                    value: 'firstName',
                    newRow: true,
                  },
                  {
                    label: 'Last Name',
                    value: 'lastName',
                  },
                  {
                    label: 'Middle Name',
                    value: 'middleName',
                  },
                  {
                    label: 'Social Security Number',
                    value: 'ssn',
                    newRow: true,
                    hide: !!id,
                  },
                  {
                    type: 'date',
                    label: 'Date of Birth',
                    value: 'dateOfBirth',
                    disableBefore: new Date('1900-01-01T00:00:00'),
                    disableAfter: new Date(),
                  },
                  {
                    label: 'Email',
                    value: 'email',
                  },
                ],
                person,
                setPerson
              )}
            </Grid>
          </Inner>

          <SectionHeader text="Address" border />
          <Inner data-disabled={currentPermission?.entities !== 'write'} pt={16}>
            <Grid>
              <FormElement fullWidth>
                <AddressField
                  onChange={(a) => {
                    setPerson({ ...person, address: a });
                    if (!a.state) {
                      personStateRef.current?.open();
                    }
                  }}
                />
              </FormElement>
              <Divider fullWidth>or</Divider>
              {generateFields<CreatePersonEntity, AddressType>(
                [
                  {
                    label: 'Line 1',
                    value: 'line1',
                  },
                  {
                    label: 'Line 2',
                    value: 'line2',
                  },
                  {
                    label: 'City',
                    value: 'city',
                  },
                  {
                    type: 'country',
                    label: 'Country',
                    value: 'countryCode',
                    countries,
                  },
                  {
                    label: 'Postal Code',
                    value: 'postalCode',
                  },
                  {
                    type: 'state',
                    typeValue: person?.address?.countryCode,
                    label: 'State',
                    value: 'state',
                    states,
                    fieldRef: personStateRef,
                  },
                ],
                person,
                setPerson,
                'address'
              )}
            </Grid>
          </Inner>
        </>
      )}
      {type === 'business' && (
        <>
          <SectionHeader text="Information" border={!id} />
          <Inner data-disabled={currentPermission?.entities !== 'write'} pt={16}>
            <Grid>
              {id && (
                <FormElement>
                  <FormLabel>ID</FormLabel>
                  <CopyInput value={id} />
                </FormElement>
              )}
              {generateFields<CreateBusinessEntity>(
                [
                  {
                    label: 'Business Name',
                    value: 'businessName',
                    newRow: true,
                  },
                  {
                    label: 'Employer Identification Number',
                    value: 'ein',
                    newRow: true,
                    hide: !!id,
                  },
                  {
                    label: 'Website',
                    value: 'website',
                  },
                ],
                business,
                setBusiness
              )}
            </Grid>
          </Inner>

          <SectionHeader text="Address" border />
          <Inner data-disabled={currentPermission?.entities !== 'write'} pt={16}>
            <Grid>
              <FormElement fullWidth>
                <AddressField
                  onChange={(a) => {
                    setBusiness({ ...business, address: a });
                    if (!a.state) {
                      businessStateRef.current?.open();
                    }
                  }}
                />
              </FormElement>
              <Divider fullWidth>or</Divider>
              {generateFields<CreateBusinessEntity, AddressType>(
                [
                  {
                    label: 'Line 1',
                    value: 'line1',
                  },
                  {
                    label: 'Line 2',
                    value: 'line2',
                  },
                  {
                    label: 'City',
                    value: 'city',
                  },
                  {
                    type: 'country',
                    label: 'Country',
                    value: 'countryCode',
                    countries,
                  },
                  {
                    label: 'Postal Code',
                    value: 'postalCode',
                  },
                  {
                    type: 'state',
                    typeValue: business?.address?.countryCode,
                    label: 'State',
                    value: 'state',
                    states,
                    fieldRef: businessStateRef,
                  },
                ],
                business,
                setBusiness,
                'address'
              )}
            </Grid>
          </Inner>

          <SectionHeader text="Beneficial Owners" border />
          {businessBeneficialOwner.map((entry: DeepPartial<PersonDetails>, index: number) => (
            <Inner pt={0} pb={12} key={index}>
              <Box variant="secondary">
                <SectionHeader text="Beneficial Owner" counter={index + 1} onClick={() => handleToggle(index)}>
                  {businessBeneficialOwnerOpen.includes(index) ? <Up /> : <Down />}
                </SectionHeader>
                <ToggleHeight isClose={!businessBeneficialOwnerOpen.includes(index)}>
                  <Inner data-disabled={currentPermission?.entities !== 'write'} pt={16}>
                    <Grid>
                      {generateFields<PersonDetails>(
                        [
                          {
                            label: 'First Name',
                            value: 'firstName',
                          },
                          {
                            label: 'Last Name',
                            value: 'lastName',
                          },
                          {
                            label: 'Middle Name',
                            value: 'middleName',
                          },
                          {
                            label: 'Social Security Number',
                            value: 'ssn',
                            newRow: true,
                            hide: !entry.isNew,
                          },
                          {
                            type: 'date',
                            label: 'Date of Birth',
                            value: 'dateOfBirth',
                            disableBefore: new Date('1900-01-01T00:00:00'),
                            disableAfter: new Date(),
                          },
                          {
                            label: 'Email',
                            value: 'email',
                          },
                        ],
                        entry,
                        (data: any) => handleBeneficialOwner(data, index)
                      )}
                    </Grid>
                  </Inner>
                  <SectionHeader text="Address" border />
                  <Inner pt={0}>
                    <Grid>
                      <FormElement fullWidth>
                        <AddressField
                          onChange={(a) => {
                            handleBeneficialOwner({ ...entry, address: a }, index);
                            if (!a.state) {
                              beneficialOwnerStateRef.current?.open();
                            }
                          }}
                        />
                      </FormElement>
                      <Divider fullWidth>or</Divider>
                      {generateFields<PersonDetails, AddressType>(
                        [
                          {
                            label: 'Line 1',
                            value: 'line1',
                          },
                          {
                            label: 'Line 2',
                            value: 'line2',
                          },
                          {
                            label: 'City',
                            value: 'city',
                          },
                          {
                            type: 'country',
                            label: 'Country',
                            value: 'countryCode',
                            countries,
                          },
                          {
                            label: 'Postal Code',
                            value: 'postalCode',
                          },
                          {
                            type: 'state',
                            typeValue: entry?.address?.countryCode,
                            label: 'State',
                            value: 'state',
                            states,
                            fieldRef: beneficialOwnerStateRef,
                          },
                        ],
                        entry,
                        (data: any) => handleBeneficialOwner(data, index),
                        'address'
                      )}
                    </Grid>
                  </Inner>
                </ToggleHeight>
              </Box>
            </Inner>
          ))}
          <ToggleTitle onClick={addBeneficialOwner}>
            <Icon.Plus />
            <span>
              <sup>{businessBeneficialOwner.length + 1}</sup> Beneficial Owner
            </span>
          </ToggleTitle>
        </>
      )}

      <Inner>
        <EditToolbar>
          <Button onClick={() => navigate(-1)} variant="secondary" size="small">
            {id ? 'Back' : 'Cancel'}
          </Button>
          <Button
            onClick={handleSubmit}
            isDisabled={currentPermission?.entities !== 'write'}
            size="small"
            icon={!id && <Icon.Plus />}
          >
            {id ? 'Save' : 'Create Entity'}
          </Button>
        </EditToolbar>
      </Inner>
    </>
  );
};
