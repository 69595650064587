import React from 'react';
import { FieldValues, Path } from 'react-hook-form';
import styled from 'styled-components';

import { FormField, FormFieldProps } from './FormField';
import { CounterpartyFieldProps, FormFieldCounterparty } from './FormFieldCounterparty';
import { Section, SectionProps } from './Section';

const TwoFieldsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

export const TwoFieldsWrapperFirst = styled.div`
  display: flex;
  gap: 20px;

  & > *:last-child {
    flex-grow: 1;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  padding: 12px;
`;

type SectionField<T extends FieldValues, P extends Path<T> | undefined> = {
  id: P extends keyof T ? Path<T[P]> : Path<T>;
  updateOn?: P extends keyof T ? Path<T[P]> : Path<T>;
  hide?: boolean;
  isCounterparty?: 'wire' | 'ach';
  nextToPrevious?: boolean;
} & Omit<FormFieldProps<T>, 'id' | 'updateOn'> &
  Partial<CounterpartyFieldProps>;

type SectionWithFields<T extends FieldValues, P extends Path<T> | undefined> = {
  fields: SectionField<T, P>[];
  subSections?: SectionWithFields<T, P>[];
} & SectionProps;

type RenderFieldsProps<T extends FieldValues, P extends Path<T> | undefined = undefined> = {
  prefix?: P | undefined;
  sections: SectionWithFields<T, P>[];
};

export const getFullPath = <T extends FieldValues, P extends Path<T> | undefined = undefined>(
  prefix: P | undefined,
  path: P extends keyof T ? Path<T[P]> : Path<T>
) => {
  return prefix ? (`${String(prefix)}.${String(path)}` as Path<T>) : (path as Path<T>);
};

export const RenderFields = <T extends FieldValues, P extends Path<T> | undefined = undefined>({
  sections,
  prefix,
}: RenderFieldsProps<T, P>) => {
  return sections.map(({ fields, subSections, ...sectionProps }, index) => (
    <Section key={index} {...sectionProps}>
      {fields
        .filter(({ hide }) => !hide)
        .map(({ isCounterparty, nextToPrevious, children, id, onLoaded, updateOn, ...fieldProps }, fieldIndex, arr) => {
          const fieldPropsTyped: Pick<FormFieldProps<T>, 'id' | 'updateOn'> = {
            id: getFullPath(prefix, id),
            updateOn: updateOn
              ? prefix
                ? (`${String(prefix)}.${String(id)}` as keyof T)
                : (id as keyof T)
              : undefined,
          };

          const fieldComponent = isCounterparty ? (
            <FormFieldCounterparty<T>
              {...fieldProps}
              {...fieldPropsTyped}
              type={isCounterparty}
              onLoaded={onLoaded}
              key={fieldIndex}
            >
              {children}
            </FormFieldCounterparty>
          ) : (
            <FormField<T> {...fieldProps} {...fieldPropsTyped} key={fieldIndex}>
              {children}
            </FormField>
          );

          if (nextToPrevious && fieldIndex > 0) {
            const entry = arr[fieldIndex - 1];
            return (
              <TwoFieldsWrapper key={fieldIndex - 1}>
                {entry.isCounterparty ? (
                  <FormFieldCounterparty<T> {...(entry as typeof entry & CounterpartyFieldProps)} {...fieldPropsTyped}>
                    {entry.children}
                  </FormFieldCounterparty>
                ) : (
                  <FormField<T> {...entry} {...fieldPropsTyped}>
                    {entry.children}
                  </FormField>
                )}
                {fieldComponent}
              </TwoFieldsWrapper>
            );
          }

          return !nextToPrevious && fieldComponent;
        })}

      {subSections && <RenderFields sections={subSections} prefix={prefix} />}
    </Section>
  ));
};
