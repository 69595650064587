import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Checkbox, Dropdown, Icon, Input, PasswordInput } from '@column/column-ui-kit';

import { Form } from '../../Login';
import { Option, OptionList, RegisterAction, RegisterContent, RegisterContentGrid, StyledTextarea } from '../Questions';
import { FileUpload, NotificationList } from '~/components';
import { LearnMore } from '~/elements';
import { useAddress } from '~/hooks';
import { ROUTE } from '~/public/routes';
import { validDocumentExtensions, GoogleFile, GoogleFileRepository, SubfolderName } from '~/repositories';
import { BusinessPageIdType, DepgraphNodeValues } from '~/repositories/PlatformRepository';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { FormElement, FormFields, FormLabel, Headline, Line, Paragraph } from '~/styles';
import { validate4Digits, validateURL } from '~/util';
import { helpSidebarContent } from '~/util/helpSidebarContent';

import { FormElementHint } from './Location';

export const FormElementSplit = styled.div`
  margin-top: 4px;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: minmax(0, 1fr) auto;
`;

export const ButtonLeft = styled(Button)`
  display: flex;
  margin-top: 4px;
  padding: 12px 0;
`;

const StyledLine = styled(Line)`
  margin: 24px 0;
`;

export const PageRegisterBusinessInformation: React.FC = () => {
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const currentPlatformState = useSessionStore((state) => state.currentPlatform);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDBA, setIsDBA] = useState<boolean>(false);
  const [sourceOfFunds, setSourceOfFunds] = useState<string[]>([]);
  const [descriptionOfPrimarySourcesOfFunds, setDescriptionOfPrimarySourcesOfFunds] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();
  const { countries } = useAddress({ types: ['country'] });
  const { states } = useAddress({ types: ['state'], countryCodes: ['US'] });
  const { control, handleSubmit, watch, reset, setValue } = useForm<DepgraphNodeValues>();

  const fileUploadCfg: { key: any; title: string; hint: string; folder: SubfolderName }[] = [
    {
      key: 'einProof',
      title: 'EIN/Tax ID verification',
      hint: 'Please upload a W-9 or any IRS document/correspondence that verifies your EIN or Tax ID.',
      folder: 'CDD',
    },
    {
      key: 'evidenceOfActiveStatus',
      title: 'Evidence of active status',
      hint: "Please upload a document or screenshot that verifies your business's active status or good standing. You can usually get this at your Secretary of State website.",
      folder: 'CDD',
    },
    {
      key: 'businessDocumentationProof',
      title: 'Business documentation',
      hint: 'Please upload one of the following documents: Articles of Organization, Certificate of Formation, Operating Agreement, Company By-Laws, or Company Agreement.',
      folder: 'CDD',
    },
  ];

  const handleSourceOfFundsChange = (optionKey: string) => {
    if (sourceOfFunds.includes(optionKey)) {
      setSourceOfFunds(sourceOfFunds.filter((k) => k !== optionKey));
      return;
    }
    setSourceOfFunds([...sourceOfFunds, optionKey]);
  };

  const onSuccess = (depgraphNodeValues: DepgraphNodeValues) => {
    if (isLoading) {
      return;
    }

    if (sourceOfFunds.length < 1) {
      addDangerNotification({
        content: 'Please select at least one option.',
        display: 'source-of-funds',
      });
      return;
    }

    if (descriptionOfPrimarySourcesOfFunds.length < 1) {
      addDangerNotification({
        content: 'Please describe your primary source of funds',
        display: 'description-of-primary-funds',
      });
      return;
    }

    type DepgraphNodeValuesKeys = keyof typeof depgraphNodeValues;
    const missingFile = fileUploadCfg.find((cfg) => {
      return !depgraphNodeValues[cfg.key as DepgraphNodeValuesKeys];
    });
    if (missingFile) {
      addDangerNotification({
        content: 'Please upload a proof documentation',
        display: missingFile.key,
      });
      return;
    }

    setIsLoading(true);

    currentPlatformState
      ?.updateDepgraphNodeValues({
        sourceOfFunds,
        descriptionOfPrimarySourcesOfFunds,
        customerType: 'entity',
        entityLegalName: depgraphNodeValues.entityLegalName,
        entityDbaName: depgraphNodeValues.entityDbaName,
        entityWebsite: depgraphNodeValues.entityWebsite,
        legalEntityType: depgraphNodeValues.legalEntityType,
        entityStateOfIncorporation: depgraphNodeValues.entityStateOfIncorporation,
        entityYearOfIncorporation: depgraphNodeValues.entityYearOfIncorporation,
        entityCountryOfIncorporation: depgraphNodeValues.entityCountryOfIncorporation,
        entityRegistrationId: depgraphNodeValues.entityRegistrationId,
        einProof: depgraphNodeValues.einProof,
        entityDetailsDocumentaryProof: depgraphNodeValues.einProof,
        evidenceOfActiveStatus: depgraphNodeValues.evidenceOfActiveStatus,
        businessDocumentationProof: depgraphNodeValues.businessDocumentationProof,
        completedPages: [
          ...new Set<BusinessPageIdType>([
            ...(currentPlatformState.depgraphNodeValues?.completedPages ?? []),
            'information',
          ]),
        ],
      })
      .then(() => {
        navigate(ROUTE.REGISTER_BUSINESS_LOCATION);
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({ currentPlatform: state.currentPlatform, isLoadingState: state.isLoading }),
        ({ currentPlatform, isLoadingState }) => {
          if (isLoadingState) {
            return;
          }

          reset({
            ...currentPlatform?.depgraphNodeValues,
            entityLegalName: !currentPlatform?.depgraphNodeValues?.entityLegalName
              ? currentPlatform?.name
                ? currentPlatform?.name
                : (currentPlatform?.companyName ?? '')
              : currentPlatform?.depgraphNodeValues?.entityLegalName,
          });

          setSourceOfFunds(currentPlatform?.depgraphNodeValues?.sourceOfFunds ?? []);
          setDescriptionOfPrimarySourcesOfFunds(
            currentPlatform?.depgraphNodeValues?.descriptionOfPrimarySourcesOfFunds ?? ''
          );

          if (currentPlatform?.depgraphNodeValues?.entityDbaName) {
            setIsDBA(true);
          }
        },
        { fireImmediately: true }
      ),
    [location.pathname]
  );

  return (
    <RegisterContent>
      <RegisterContentGrid>
        <Headline>Business information</Headline>
        <Paragraph size="small">
          As a bank, we are required to collect and verify information about your business, in a process called KYC.
        </Paragraph>
      </RegisterContentGrid>
      <Form onSubmit={handleSubmit(onSuccess)}>
        <NotificationList display="page" />
        <RegisterContent>
          <FormFields>
            <FormElement>
              <FormElement>
                <FormLabel>Business legal name</FormLabel>
                <FormElementHint>This is the full name you use on legal documents, tax forms, etc.</FormElementHint>
                <Controller
                  name="entityLegalName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error, isTouched } }) => (
                    <Input placeholder="Business Name" hasError={isTouched && !!error} {...field} />
                  )}
                />
              </FormElement>

              {!isDBA && (
                <ButtonLeft variant="subtle" type="button" icon={<Icon.Plus />} onClick={() => setIsDBA(true)}>
                  Add DBA (Doing Business As) Name
                </ButtonLeft>
              )}

              {isDBA && (
                <FormElementSplit>
                  <Controller
                    name="entityDbaName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: isDBA,
                    }}
                    render={({ field, fieldState: { error, isTouched } }) => (
                      <Input placeholder="DBA (Doing Business As) name" hasError={isTouched && !!error} {...field} />
                    )}
                  />
                  <Button
                    variant="muted"
                    type="button"
                    icon={<Icon.Cross />}
                    onlyIcon
                    onClick={() => setIsDBA(false)}
                  />
                </FormElementSplit>
              )}
            </FormElement>

            <FormElement>
              <FormLabel>Website</FormLabel>
              <Controller
                name="entityWebsite"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  validate: validateURL,
                }}
                render={({ field, fieldState: { error, isTouched } }) => (
                  <Input placeholder="website.com" hasError={isTouched && !!error} {...field} />
                )}
              />
            </FormElement>

            <div>
              <FormElement>
                <FormLabel>Legal entity type</FormLabel>
                <Controller
                  name="legalEntityType"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <Dropdown
                      onChange={field.onChange}
                      active={field.value}
                      hasError={!!error}
                      options={[
                        {
                          label: 'Corporation',
                          value: 'Corporation',
                        },
                        {
                          label: 'Limited Partnership',
                          value: 'Limited Partnership',
                        },
                        {
                          label: 'General Partnership',
                          value: 'General Partnership',
                        },
                        {
                          label: 'LLC',
                          value: 'LLC',
                        },
                        {
                          label: 'Sole Prop',
                          value: 'Sole Prop',
                        },
                        {
                          label: 'Trust',
                          value: 'Trust',
                        },
                        {
                          label: 'Professional Association',
                          value: 'Professional Association',
                        },
                        {
                          label: 'PIC/PIV',
                          value: 'PIC/PIV',
                        },
                        {
                          label: 'SPV',
                          value: 'SPV',
                        },
                      ]}
                      fullWidth
                      variant="muted"
                    />
                  )}
                />
              </FormElement>
            </div>

            <FormElement>
              <FormLabel>State of incorporation</FormLabel>
              <FormElementHint>
                This is the state in which your company is registered with the Secretary of State or similar authority.
              </FormElementHint>
              <Controller
                name="entityStateOfIncorporation"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <Dropdown
                    onChange={field.onChange}
                    active={field.value}
                    hasError={!!error}
                    search
                    fullWidth
                    options={states
                      .filter((s) => {
                        return s.type === 'state' || s.type === 'district';
                      })
                      .map((s) => ({
                        label: `${s.name} (${s.state_code})`,
                        value: s.state_code,
                      }))}
                    variant="muted"
                  />
                )}
              />
            </FormElement>

            <FormElement>
              <FormLabel>Country of incorporation</FormLabel>
              <FormElementHint>This is the country in which your company is originally registered.</FormElementHint>
              <Controller
                name="entityCountryOfIncorporation"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <Dropdown
                    onChange={field.onChange}
                    active={field.value}
                    hasError={!!error}
                    search
                    fullWidth
                    options={countries.map((c) => ({
                      label: c.name,
                      value: c.code,
                    }))}
                    variant="muted"
                  />
                )}
              />
            </FormElement>

            <FormElement>
              <FormLabel>Year of incorporation</FormLabel>
              <Controller
                name="entityYearOfIncorporation"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  validate: validate4Digits,
                }}
                render={({ field, fieldState: { error, isTouched } }) => (
                  <Input placeholder="i.e. 2018" maxLength={4} hasError={isTouched && !!error} {...field} />
                )}
              />
            </FormElement>

            <FormElement>
              <FormLabel>Employer Identification Number (EIN) or tax ID</FormLabel>
              <Controller
                name="entityRegistrationId"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({ field, fieldState: { error, isTouched } }) => (
                  <PasswordInput
                    placeholder="00-0000000"
                    mask="##-#######"
                    hasError={isTouched && !!error}
                    {...field}
                  />
                )}
              />
            </FormElement>

            <div>
              <NotificationList display="source-of-funds" />

              <FormElement>
                <FormLabel>
                  Source of funds {'  '}
                  <LearnMore headline="Source of funds">{helpSidebarContent.sourceOfFunds}</LearnMore>
                </FormLabel>
                <div>
                  <OptionList>
                    {['Investor Funded', 'Existing Deposits', 'Business Operations', 'Loan'].map(
                      (optionKey: string) => {
                        return (
                          <Option
                            isActive={sourceOfFunds.includes(optionKey)}
                            isSimple
                            key={optionKey}
                            onClick={() => handleSourceOfFundsChange(optionKey)}
                          >
                            <Checkbox
                              isChecked={sourceOfFunds.includes(optionKey)}
                              onCheckedChange={() => handleSourceOfFundsChange(optionKey)}
                              label={optionKey}
                            />
                          </Option>
                        );
                      }
                    )}
                  </OptionList>
                </div>
              </FormElement>
            </div>

            <FormElement>
              <FormLabel>
                Description of primary source of funds{'  '}
                <LearnMore headline="Source of funds description">{helpSidebarContent.sourceOfFundsDetail}</LearnMore>
              </FormLabel>
              <div>
                <NotificationList display="description-of-primary-funds" />

                <StyledTextarea
                  placeholder="A few sentences going into more detail on the sources you have selected above."
                  onChange={setDescriptionOfPrimarySourcesOfFunds}
                  value={descriptionOfPrimarySourcesOfFunds}
                />
              </div>
            </FormElement>
          </FormFields>

          <RegisterContent>
            {fileUploadCfg.map((cfg) => {
              return (
                <RegisterContentGrid key={cfg.key}>
                  <StyledLine />
                  <Headline size="middle">{cfg.title}</Headline>
                  <Paragraph size="small">{cfg.hint}</Paragraph>
                  <NotificationList display={cfg.key} />
                  <FileUpload<GoogleFile>
                    handleUpload={(file) =>
                      GoogleFileRepository.upload(
                        {
                          subfolderName: cfg.folder,
                          file,
                        },
                        currentPlatformState?.id as string
                      )
                    }
                    name={cfg.key}
                    description={cfg.title}
                    onFileUpload={(file: GoogleFile) => setValue(cfg.key, file)}
                    onFileRemove={(file: GoogleFile) => setValue(cfg.key, undefined)}
                    renderFileName={(file) => <>{file?.fileName}</>}
                    allowedExtensions={validDocumentExtensions}
                    file={watch(cfg.key)}
                  />
                </RegisterContentGrid>
              );
            })}
          </RegisterContent>
        </RegisterContent>
        <RegisterAction>
          <Button variant="muted" onClick={() => navigate(ROUTE.REGISTER_OPEN_BANK_ACCOUNT)} type="button">
            Back
          </Button>
          <Button isLoading={isLoading} isDisabled={false}>
            Continue
          </Button>
        </RegisterAction>
      </Form>
    </RegisterContent>
  );
};
